import React, { Component } from 'react';
import styled from 'styled-components';
import MarketingVid from './MarketingVid';
import { Icon } from '@blueprintjs/core';
import LazyLoad from 'react-lazy-load';
import LinkButton from '../shared/LinkButton';

const StyledHomepageFeatures = styled.div`
  background: #fff;
  padding: 80px 15px;

  .carousel__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel__nav {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      width: 300px;
      padding: 15px;
      cursor: pointer;
      user-select: none;
      opacity: 0.5;
      transition: all 120ms ease-in-out;
      position: relative;

      p {
        font-size: 13px;
        margin-bottom: 0;
        color: ${props => props.theme.colorGrayDarker};
      }

      h4 {
        margin-bottom: 8px;
        border-bottom: 1px solid ${props => props.theme.colorGrayLighter};
        padding-bottom: 10px;
      }

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          opacity: 1;
        }
      }

      &.active {
        opacity: 1;

        h4 {
          font-weight: 600;
        }
      }

      .icon-bg {
        position: absolute;
        top: 17px;
        right: 20px;
        opacity: 0.3;
      }
    }
  }

  .carousel__content {
    margin-left: 30px;
    min-height: 400px;

    h4 {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .carousel__content-slide {
    display: none;
    padding-top: 30px;
    min-width: 600px;

    p {
      display: none;
      max-width: 500px;
      font-size: 1.1rem;
      margin: 15px auto 0;
      width: 100%;
    }

    h4 {
      display: none;
      text-align: center;
    }

    .divider {
      display: none;
    }

    &.active {
      display: block;
    }
  }

  .learn-more {
    text-align: center;
    padding-top: 2rem;
  }

  @media screen and (max-width: 1000px) {
    .carousel__wrapper {
      flex-direction: column;
      align-items: center;
      margin-top: -20px;
    }

    .carousel__nav {
      width: 100%;
      margin-bottom: 10px;

      > ul {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        overflow-x: auto;

        li {
          width: auto;
          padding: 10px;

          h4 {
            font-size: 14px;
            white-space: nowrap;
            border: none;
            padding: 0;
          }

          p {
            display: none;
          }

          &.active {

            h4 {
              color: ${props => props.theme.colorPrimary};
              position: relative;

              :after {
                content: '';
                width: 100%;
                position: absolute;
                height: 1px;
                left: 0;
                bottom: -8px;
                background-color: ${props => props.theme.colorTealDarker};
              }
            }
          }

          .icon-bg {
            display: none;
          }
        }
      }
    }

    .carousel__content {
      margin-left: 0;
      min-height: auto;
    }

    .carousel__content-slide {
      min-width: 0;
      width: 100%;
      max-width: 600px;
      
      p {
        display: block;
      }
    }
  }

  @media screen and (max-width: 580px) {
    .carousel__nav {
      display: none;
    }

    .carousel__content-slide {
      display: block;
      margin-bottom: 30px;

      h4, .divider {
        display: block;
      }
    }
  }
`

class HomepageFeatures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      auto: true
    }
  }

  componentDidMount() {
    this.autoChange = setInterval(() => this.incrementSlides(), 6000);
  }

  componentWillUnmount() {
    clearInterval(this.autoChange);
  }

  incrementSlides = () => {
    // Only run if auto is still true
    if (this.state.auto) {
      if (this.state.current !== 3) {
        this.setState(state => ({
          current: state.current + 1
        }));
      } else {
        this.setState({
          current: 0
        });
      }
    }
  }

  changeSlide = index => {
    this.setState({
      current: index
    })
    // If user initiates slide change, then remove auto incrementing
    if (this.state.auto) {
      this.setState({
        auto: false
      })
    }
  }

  render() {
    return (
      <StyledHomepageFeatures>
        <div className="marketing-section__header">
          <h2>Your personal, all-in-one travel assistant</h2>
          <div className="divider divider--m"></div>
          <p>Stop spending hours jumping from site to site. We have it all right here.</p>
        </div>
        <div className="carousel__wrapper">
          <div className="carousel__nav">
            <ul>
              <li className={`${this.state.current === 0 ? 'active' : ''}`} onClick={() => this.changeSlide(0)}>
                <h4 className="with-shape"><div className="shape square bg-orange-dark"></div>Personalized Guides</h4>
                <p>Quickly understand your destination with personalized tips, comparisons to home, and clear call outs of what&apos;s important.</p>
                <div className="icon-bg"><Icon icon="document" iconSize={14} /></div>
              </li>
              <li className={`${this.state.current === 1 ? 'active' : ''}`} onClick={() => this.changeSlide(1)}>
                <h4 className="with-shape"><div className="shape circle bg-pink-dark"></div>Prefilled Checklists</h4>
                <p>Important to-dos and due dates are automatically created for you from destination, trip dates, your nationality, and more.</p>
                <div className="icon-bg"><Icon icon="tick" iconSize={16} /></div>
              </li>
              <li className={`${this.state.current === 2 ? 'active' : ''}`} onClick={() => this.changeSlide(2)}>
                <h4 className="with-shape"><div className="shape parallelogram bg-purple-dark"></div>Custom Packing Lists</h4>
                <p>Forget something? Not anymore. Packing lists are automatically built from weather, trip activities, trip length, and more.</p>
                <div className="icon-bg"><Icon icon="briefcase" iconSize={14} /></div>
              </li>
              <li className={`${this.state.current === 3 ? 'active' : ''}`} onClick={() => this.changeSlide(3)}>
                <h4 className="with-shape"><div className="shape triangle bc-yellow-dark"></div>Trip Timeline</h4>
                <p>See your entire trip at a glance with to-dos, weather, flights (coming soon), and all destinations clearly laid out in a single timeline.</p>
                <div className="icon-bg"><Icon icon="timeline-events" iconSize={16} /></div>
              </li>
            </ul>
          </div>
          <div className="carousel__content">
            <div className={`carousel__content-slide ${this.state.current === 0 ? 'active' : ''}`}>
              <h4 className="with-shape"><div className="shape square bg-orange-dark"></div>Personalized Guides</h4>
              <MarketingVid id="guides" src={process.env.PUBLIC_URL + '/feature-guides.mp4'} alt="Personalized Guides" />
              <p>Quickly understand your destination with personalized tips, comparisons to home, and clear call outs of what&apos;s important.</p>
              <div className="divider divider--m divider--thick"></div>
            </div>
            <div className={`carousel__content-slide ${this.state.current === 1 ? 'active' : ''}`}>
              <h4 className="with-shape"><div className="shape circle bg-pink-dark"></div>Prefilled Checklists</h4>
              <LazyLoad offsetVertical={100}>
                <MarketingVid id="checklists" src={process.env.PUBLIC_URL + '/feature-checklists.mp4'} alt="Personalized Checklists" />
              </LazyLoad>
              <p>Prepare in no time. Important to-dos and due dates are automatically created for you from destination, trip dates, your nationality, and more.</p>
              <div className="divider divider--m divider--thick"></div>
            </div>
            <div className={`carousel__content-slide ${this.state.current === 2 ? 'active' : ''}`}>
              <h4 className="with-shape"><div className="shape triangle bc-yellow-dark"></div>Custom Packing Lists</h4>
              <LazyLoad hoffsetVertical={100}>
                <MarketingVid id="packing" src={process.env.PUBLIC_URL + '/feature-packing.mp4'} alt="Personalized Packing Lists" />
              </LazyLoad>
              <p>Forget something? Not anymore. Packing lists are automatically built from weather, trip activities, trip length, and more.</p>
              <div className="divider divider--m divider--thick"></div>
            </div>
            <div className={`carousel__content-slide ${this.state.current === 3 ? 'active' : ''}`}>
              <h4 className="with-shape"><div className="shape parallelogram bg-purple-dark"></div>Trip Timeline</h4>
              <LazyLoad hoffsetVertical={100}>
                <MarketingVid id="timeline" src={process.env.PUBLIC_URL + '/feature-timeline.mp4'} alt="Trip Timeline" />
              </LazyLoad>
              <p>See your entire trip at a glance with to-dos, weather, flights (coming soon), and all destinations clearly laid out in a single timeline.</p>
            </div>
          </div>
        </div>
        <div className="learn-more">
          <LinkButton large intent="primary" rightIcon="caret-right" to='/why'>Learn more about why Wanderium</LinkButton>
        </div>
      </StyledHomepageFeatures>
    );
  }
}

export default HomepageFeatures;