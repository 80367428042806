import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '@blueprintjs/core';

const LinkButton = (props) => {
  const {
    history,
    location,
    match,
    staticContext,
    to,
    fill,
    onClick,
    // ⬆ filtering out props that `button` doesn’t know what to do with.
    ...rest
  } = props
  return (
    <Link to={to} className={`link-button${fill === true ? ' --fill' : ''}`}>
      <Button
        {...rest} // `children` is just another prop!
        fill={fill}
      />
    </Link>
  )
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired
}

export default withRouter(LinkButton)