import React, { Component } from 'react';
import styled from 'styled-components';

const StyledHomepageHow = styled.div`
  display: flex;
  justify-content: center;
  padding: 80px 15px;
  background: ${props => props.theme.colorBlushLight};

  .divider {
    display: none;
  }

  .how__header {
    margin-top: -3px;
  }

  .how__content {
    max-width: 400px;
    margin-left: 50px;
    padding-left: 40px;

    .how__step:last-of-type {
      padding-bottom: 0;
    }
  }

  .how__step {
    position: relative;
    padding-bottom: 30px;

    h4 {
      margin-bottom: 5px;
    }

    p {
      color: ${props => props.theme.colorGrayDarker};
      margin-bottom: 0;
    }

    &.--with-line {
      :after {
        content: '';
        position: absolute;
        top: 0;
        left: -41px;
        height: 110%;
        width: 1px;
        background-color: ${props => props.theme.colorGray};
      }
    }
  }

  .shape {
    position: absolute;
    left: -56px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 0 4px ${props => props.theme.colorBlushLight};
    }
    
    span {
      font-family: ${props => props.theme.fontHeading};
      position: relative;
      z-index: 2;
      top: -1px;
      color: #fff;
      font-size: 19px;
      user-select: none;
    }

    &.circle {
      background: ${props => props.theme.colorBlueDark};
    }

    &.square {
      width: 0.9em;
      height: 0.9em;
      left: -55px;
      background: ${props => props.theme.colorGreenDark};
      transform: rotate(9deg);
    }

    &.triangle {
      border-color: ${props => props.theme.colorOrangeDark};
      transform: rotate(-10deg) translateY(-4px);

      &:after {
        border-left: 0.52em solid transparent;
        border-right: 0.52em solid transparent;
        border-bottom: 1em solid;
        border-bottom-color: inherit;
      }

      &:before {
        box-shadow: 0 0 0 2px ${props => props.theme.colorBlushLight}, 0 0 0 8px inset ${props => props.theme.colorBlushLight};
      }

      span {
        top: 4px;
        left: 1px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;

    .how__header {
      margin-bottom: 15px;
    }

    .how__content {
      margin-left: 16px;
    }

    .divider {
      display: block;
    }
  }
`

class HomepageHow extends Component {

  render() {
    return (
      <StyledHomepageHow>
        <div className="how__header">
          <h2>How it works</h2>
          <div className="divider divider--s"></div>
        </div>
        <div className="how__content">
          <div className="how__step --with-line">
            <div className="shape shape--m circle"><span>1</span></div>
            <h4>Describe you and your trip</h4>
            <p>Fill out your travel profile and trip details.</p>
          </div>
          <div className="how__step --with-line">
            <div className="shape shape--m square"><span>2</span></div>
            <h4>We&#39;ll prepare you</h4>
            <p>What to do and when to do it.</p>
          </div>
          <div className="how__step">
            <div className="shape shape--m triangle"><span>3</span></div>
            <h4>Take off worry free</h4>
            <p>Know you&#39;ve taken care of everything.</p>
          </div>
        </div>
      </StyledHomepageHow>
    );
  }
}

export default HomepageHow;