import React, { Component } from "react";
import { withRouter, Route, Redirect } from "react-router-dom";

// Component for passing all props of a route into its rendered component
// Adds authentication
class PrivateRouteWrapper extends Component {
  render() {
    //console.log('PrivateRouterWrapper: ', this.props);
    const { Component, authState, ...rest } = this.props;

    // If Auth is loading, wait for it
    if (authState === 'loading') {
      return (null);
    }

    return (
      <Route
        {...rest}
        render={props => (
          authState === 'signedIn'
            ? <Component {...rest} {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: { from: props.location }
              }} />
      )} />
    );
  }
}

export default withRouter(PrivateRouteWrapper);