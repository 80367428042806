import React, { Component } from 'react';
import styled from 'styled-components';
import { Spinner } from "@blueprintjs/core";

const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

class Loading extends Component {
  render() {
    return (
      <StyledLoading>
        <Spinner />
      </StyledLoading>
    )
  }
}

export default Loading;