import gql from 'graphql-tag';

export default gql`
  query getUser {
    getUser {
      id
      nameFirst
      nameLast
      passportCountry
      passportExpiration
      home {
        id
        name
        country
      }
      birthday
      gender
      languages
      timezone
      onboarded
      createTime
      type
      stripeId
      tripCountTotal
      tripsAllowed
      canceledTrips {
        tripId
        cancelTime
        origin {
          id
          name
          country
        }
        startDate
        legs {
          dest {
            id
            name
            country
          }
          date
        }
      }
    }
  }
`