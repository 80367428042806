import React, { Component } from 'react';
import styled from 'styled-components';

const emojiArray = ['🏝', '🏖', '🏔', '🏜', '🏕', '🌎', '🗺'];

const StyledEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 10px 30px;

  .empty-emoji {
    font-size: 60px;
    padding: 0 15px 15px;
    margin-right: -5px;
  }

  .empty-title {
    font-size: 18px;
    font-weight: 400;
    margin: -10px 0 20px;
    color: ${props => props.theme.colorGrayDarker};
  }

  .empty-descr {
    font-size: 15px;
    color: ${props => props.theme.colorGrayDark};
    margin-top: -15px;
    text-align: center;
    padding: 0 20px 25px;
    max-width: 450px;
  }

  &.empty--card {
    margin: 0;
    padding: 15px 20px 20px;
    background-color: #fff;
    border-top: 1px solid ${props => props.theme.colorGrayLighter};
  }

  &.empty--tall {
    padding: 80px 0;
  }
`

class EmptyState extends Component {
  constructor(props) {
    super(props);
    this.emoji = emojiArray[Math.floor(Math.random()*emojiArray.length)]; // So emoji doesn't change on re-render
  }

  render() {
    const { title, descr, card, tall } = this.props;
    return (
      <StyledEmptyState className={`empty-state ${card ? 'empty--card' : ''}${tall ? 'empty--tall' : ''}`}>
        <div className="empty-emoji">{this.emoji}</div>
        <div className="empty-title">{title}</div>
        {descr && <div className="empty-descr">{descr}</div>}
        {this.props.children}
      </StyledEmptyState>
    )
  }
}

export default EmptyState;