import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import RouteWrapper from './components/RouteWrapper';
import PrivateRouteWrapper from './components/PrivateRouteWrapper';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import ConfirmSignUp from './components/auth/ConfirmSignUp';
import LaunchGate from './components/onboarding/LaunchGate';
import Homepage from './components/marketing/Homepage';
import About from './components/marketing/About';
import Why from './components/marketing/Why';
import Pricing from './components/marketing/Pricing';
import Terms from './components/marketing/Terms';
import Privacy from './components/marketing/Privacy';
import { FocusStyleManager } from "@blueprintjs/core";
import scriptLoader from 'react-async-script-loader';
import NoMatch from './components/NoMatch';
import * as Sentry from '@sentry/browser';
import ErrorBoundary from './components/shared/ErrorBoundary';
import LoadingPage from './components/shared/LoadingPage';
import { MixpanelConsumer } from './components/shared/MixpanelContext';
const PrivateComponents = React.lazy(() => import('./PrivateComponents'));
const OnboardIntro = React.lazy(() => import('./components/onboarding/OnboardIntro'));
const ForgotPassword = React.lazy(() => import('./components/auth/ForgotPassword'));
const CountryPage = React.lazy(() => import('./components/countries/CountryPage'));
const RecsPage = React.lazy(() => import('./components/recs/RecsPage'));
const RecTopicsPage = React.lazy(() => import('./components/recs/RecTopicsPage'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmTemp: {}
    }
    // Only set Sentry if it's production
    Sentry.init({
      dsn: process.env.NODE_ENV === 'production' ? "https://46d80af006824695a6d4a8381bd0b111@sentry.io/1444991" : null
    });
  }

  handleConfirmStore = (user, pass) => {
    // Temp storage of user credentials so they can be signed in automatically after confirming email
    this.setState({
      confirmTemp: {
        user,
        pass
      }
    });
    //console.log('confirmTemp state: ', this.state.confirmTemp);
  }

  render() {
    FocusStyleManager.onlyShowFocusOnTabs();

    return (
      <ErrorBoundary>
        <MixpanelConsumer>
          {mixpanel => (
            <Suspense fallback={LoadingPage}>
              <Switch>
                <RouteWrapper exact path="/" {...this.props} mixpanel={mixpanel} Component={Homepage} />
                <RouteWrapper exact path="/about" mixpanel={mixpanel} Component={About} />
                <RouteWrapper exact path="/why" mixpanel={mixpanel} Component={Why} />
                <RouteWrapper exact path="/pricing" mixpanel={mixpanel} Component={Pricing} />
                <RouteWrapper exact path="/privacy" mixpanel={mixpanel} Component={Privacy} />
                <RouteWrapper exact path="/terms" mixpanel={mixpanel} Component={Terms} />
                <RouteWrapper exact path="/login" mixpanel={mixpanel} {...this.props} handleConfirmStore={this.handleConfirmStore} Component={SignIn} />
                <RouteWrapper exact path="/forgot" mixpanel={mixpanel} {...this.props} Component={ForgotPassword} />
                <RouteWrapper exact path="/signup" mixpanel={mixpanel} {...this.props} handleConfirmStore={this.handleConfirmStore} Component={SignUp} />
                <RouteWrapper exact path="/confirm" mixpanel={mixpanel} {...this.props} confirmTemp={this.state.confirmTemp} Component={ConfirmSignUp} />
                <RouteWrapper exact path="/country/:slug" mixpanel={mixpanel} Component={CountryPage} />
                <RouteWrapper exact path="/recs/" mixpanel={mixpanel} Component={RecTopicsPage} />
                <RouteWrapper exact path="/recs/:recTopic" mixpanel={mixpanel} Component={RecsPage} />
                <PrivateRouteWrapper
                  path="/welcome"
                  {...this.props}
                  mixpanel={mixpanel}
                  Component={OnboardIntro}
                />
                <PrivateRouteWrapper
                  path="/soon"
                  {...this.props}
                  mixpanel={mixpanel}
                  Component={LaunchGate}
                />
                <PrivateRouteWrapper
                  path={["/trips", "/trip", "/settings"]}
                  {...this.props}
                  mixpanel={mixpanel}
                  Component={PrivateComponents}
                />
                <Route render={props => (
                  <NoMatch mixpanel={mixpanel} />
                )} />
              </Switch>
            </Suspense>
          )}
        </MixpanelConsumer>
      </ErrorBoundary>
    )
  }
}

const AppWithGmaps = scriptLoader(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`)(App);
// Detect if it's prerender. If it is, don't include gmaps script otherwise it gets included twice
export default document.getElementById("root").hasChildNodes() ? App : AppWithGmaps;