import React, { Component } from 'react';
import styled from 'styled-components';
import AngledBorder from '../shared/AngledBorder';

const StyledHomepageQuotes = styled.div`
  padding: 70px 15px 80px;
  background: ${props => props.theme.colorBeigeLighter};

  .quotes {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
    padding: 15px 10px 0;
    
    .quote-wrapper:first-of-type {
      margin-left: 0;
    }
  }

  .quote-wrapper {
    margin-left: 40px;
  }

  .quote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 280px;
    padding: 25px;
    background: #fff;
    position: relative;
    z-index: 1;

    &:after {
      content: '“';
      position: absolute;
      left: 23px;
      top: 0px;
      font-size: 90px;
      opacity: 0.15;
      font-family: sans-serif;
      transform: rotate(-30deg);
    }
  }

  .quote-content {
    font-size: 1.44rem;
    font-weight: 300;
  }

  .quote-author {
    font-size: 1.2rem;
    font-weight: 300;
    text-align: right;
    color: ${props => props.theme.colorGrayDark};
  }

  @media screen and (max-width: 760px) {
    .quotes {
      flex-direction: column;
      align-items: center;

      .quote-wrapper:first-of-type {
        margin-top: 0;
      }
    }

    .quote-wrapper {
      margin-left: 0;
      margin-top: 40px;
    }
  }
`

class HomepageQuotes extends Component {

  render() {
    return (
      <StyledHomepageQuotes>
        <div className="marketing-section__header">
          <h2>What travelers are saying</h2>
          <div className="divider divider--m"></div>
          <p>Don&apos;t take our word for it. See what our users have to say.</p>
        </div>
        <div className="quotes">
          <div className="quote-wrapper">
            <AngledBorder>
              <div className="quote">
                <p className="quote-content">
                  This really helped with the anxiety of trip planning.
                </p>
                <div className="quote-author">
                  — Chelsea Z.
                </div>
              </div>
            </AngledBorder>
          </div>
          <div className="quote-wrapper">
            <AngledBorder>
              <div className="quote">
                <p className="quote-content">
                  I just got back from a trip and Wanderium was invaluable.
                </p>
                <div className="quote-author">
                  — Alex E.
                </div>
              </div>
            </AngledBorder>
          </div>
          <div className="quote-wrapper">
            <AngledBorder>
              <div className="quote">
                <p className="quote-content">
                  I was impressed with all the information available! This is a great idea.
                </p>
                <div className="quote-author">
                  — Sarah B.
                </div>
              </div>
            </AngledBorder>
          </div>
        </div>
      </StyledHomepageQuotes>
    )
  }
}

export default HomepageQuotes;