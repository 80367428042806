import React, { Component } from 'react';
import styled from 'styled-components';
import LinkButton from '../shared/LinkButton';

const StyledMarketingSignUp = styled.div`
  text-align: center;
  padding: 100px 15px;
  background: ${props => props.theme.colorTealDarkest};
  background-image: url(${process.env.PUBLIC_URL + '/bg-signup.jpg'});
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 0;

  h2 {
    margin-bottom: 20px;
    color: #fff;
    font-weight: 600;
    font-size: 1.44rem;
  }

  .bp3-button.bp3-intent-primary {
    background-color: ${props => props.theme.colorYellowDarker};

    @media (pointer: fine) and (hover: hover) {
      &:hover {
        background-color: ${props => props.theme.colorYellowDark};
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #103940ba;
  }
`

class MarketingSignUp extends Component {

  render() {
    return (
      <StyledMarketingSignUp>
        <h2>Don&apos;t leave your expensive trip up to chance.<br/>Prepare with Wanderium.</h2>
        <LinkButton large intent="primary" rightIcon="caret-right" to='/signup'>Sign up for free</LinkButton>
      </StyledMarketingSignUp>
    );
  }
}

export default MarketingSignUp;