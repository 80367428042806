import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import EmptyState from './EmptyState';
import { Button } from '@blueprintjs/core';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({eventId});
    });
  }

  render() {
    if (this.state.error) {
      return (
        <EmptyState title="Woops, something went wrong">
          <Button text="Tell us what happened" intent="primary" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })} />
        </EmptyState>
      );
    } else {
      //when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;