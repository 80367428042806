import React, { Component } from 'react';
import { FormGroup, Button } from "@blueprintjs/core";
import { Box } from 'reflexbox';
import { Formik } from 'formik';
import * as yup from 'yup';
import Auth from '@aws-amplify/auth';
import { withRouter } from 'react-router-dom';
import Logo from '../shared/Logo';
import AngledBorder from '../shared/AngledBorder';

const validationSchema = yup.object().shape({
  code: yup.string().required('Code is required'),
})

class ConfirmSignUp extends Component {
  componentDidMount() {
    this.props.mixpanel.track('Confirm Sign Up Viewed');
  }

  handleConfirm = (values, actions) => {
    if (this.props.authState === 'confirmSignUp') {
      Auth.confirmSignUp(this.props.authData, values.code)
        .then(() => {
          //actions.setSubmitting(false);
          this.props.mixpanel.track('Account Confirmed');

          // Success -> Sign user in for them
          Auth.signIn(this.props.confirmTemp.user, this.props.confirmTemp.pass)
            .then(() => {
              // Successfully signed in
              this.props.mixpanel.track('Logged In');
              this.props.onStateChange('signedIn');
              this.props.history.push('/welcome');
            })
            .catch(err => {
              if (err.code === "NotAuthorizedException") {
                // Redirect to SignIn to fix password
                this.props.history.push({ pathname: '/login', state: { msg: 'Password is incorrect' } })
              } else {
                //console.log('signin error: ', err);
                actions.setSubmitting(false);
                actions.setErrors({ general: err.message })
              }
            });
        })
        .catch((err) => {
          //console.log('confirm sign up error: ', err);
          actions.setSubmitting(false);
          actions.setErrors({ general: err.message })
        });
    } else if (this.props.authState === 'confirmEmail') {
      // Sign user back in first
      Auth.signIn(this.props.confirmTemp.user, this.props.confirmTemp.pass)
        .then(() => {
          // Confirm email
          Auth.verifyCurrentUserAttributeSubmit('email', values.code)
            .then(() => {
              //actions.setSubmitting(false)
              this.props.onStateChange('signedIn');
              this.props.history.push('/trips');
            })
            .catch(err => {
              // If error, sign back out
              Auth.signOut();
              this.props.history.push({ pathname: '/login', state: { msg: 'Confirmation code is incorrect' } })
            });
        })
        .catch(err => {
          if (err.code === "NotAuthorizedException") {
            // Redirect to SignIn to fix password
            this.props.history.push({ pathname: '/login', state: { msg: 'Password is incorrect' } })
          } else {
            Auth.signOut();
            //console.log('signin error: ', err);
            actions.setSubmitting(false);
            actions.setErrors({ general: err.message })
          }
        });
    }

  }

  render() {
    //console.log('ConfirmSignUp props: ', this.props);

    // Redirect user if they're not in confirm step
    if (!this.props.authData) {
      this.props.history.push('/login');
    }

    return (
      <div className="auth-wrapper">
        <Box w={270}>
          <div className="auth-logo">
            <Logo />
          </div>
          <div className="auth-header">
            <h3>Confirm your email</h3>
          </div>
          <AngledBorder>
            <div className="auth-form">
              <Formik
                initialValues={{
                  code: ''
                }}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={this.handleConfirm}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormGroup
                      label="Code"
                      labelFor="code"
                      intent={errors.code && touched.code && 'DANGER'}
                      helperText={`A 6 digit code was sent to ${this.props.authData}`}
                    >
                      <input
                        type="text"
                        inputMode="numeric"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        name="code"
                        id="code"
                        className={`bp3-input ${errors.code && touched.code && 'bp3-intent-danger'}`}
                        autoComplete="new-password"
                        placeholder="******"
                        autoFocus
                      />
                    </FormGroup>
                    {errors.general && <div style={{color: "red", padding: "0 0 15px"}}>{errors.general}</div>}
                    <Button fill text="Confirm" intent="primary" type="submit" disabled={isSubmitting} loading={isSubmitting} onSubmit={handleSubmit} />
                  </form>
                )}
              </Formik>
            </div>
          </AngledBorder>
        </Box>
      </div>
    )
  }
}

export default withRouter(ConfirmSignUp);