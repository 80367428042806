import React, { Component } from 'react';
import styled from 'styled-components';
import { Suggest } from "@blueprintjs/select";
import { COUNTRY_LIST as countryItems, renderCountry, filterCountry } from '../forms/Select';
import { MenuItem, Icon } from "@blueprintjs/core";
import { withRouter, Link } from 'react-router-dom';
import { randomNoRepeats } from '../../helpers/generalHelpers';

const featuredCountries = [
  {
    name: 'Portugal',
    slug: 'portugal',
    pic: 'https://wanderium.imgix.net/public/city-lisbon.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Turkey',
    slug: 'turkey',
    pic: 'https://wanderium.imgix.net/public/city-istanbul.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Norway',
    slug: 'norway',
    pic: 'https://wanderium.imgix.net/public/city-oslo.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Vietnam',
    slug: 'vietnam',
    pic: 'https://wanderium.imgix.net/public/city-hochiminh.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Malaysia',
    slug: 'malaysia',
    pic: 'https://wanderium.imgix.net/public/city-kualalumpur.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Peru',
    slug: 'peru',
    pic: 'https://wanderium.imgix.net/public/city-lima.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Argentina',
    slug: 'argentina',
    pic: 'https://wanderium.imgix.net/public/city-mendoza.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Colombia',
    slug: 'colombia',
    pic: 'https://wanderium.imgix.net/public/city-medellin.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'South Korea',
    slug: 'south-korea',
    pic: 'https://wanderium.imgix.net/public/city-seoul.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Taiwan',
    slug: 'taiwan',
    pic: 'https://wanderium.imgix.net/public/city-taipei.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'Morocco',
    slug: 'morocco',
    pic: 'https://wanderium.imgix.net/public/city-fes.jpg?w=120&dpr=2&q=65'
  },
  {
    name: 'South Africa',
    slug: 'south-africa',
    pic: 'https://wanderium.imgix.net/public/city-capetown.jpg?w=120&dpr=2&q=65'
  }
]

const StyledExploreSection = styled.div`
  width: 100%;
  background: ${props => props.theme.colorBeigeLighter};
  margin: 0 auto;
  padding: ${props => props.tall ? '53px 15px 68px' : '43px 15px 58px'};

  .explore-section__wrapper {
    max-width: 600px;
    text-align: center;
    margin: 0 auto;
  }

  .explore-section__country-suggest {
    h2 {
      font-weight: 400;
      margin-bottom: 15px;
      font-size: 1.2rem;
    }
  }

  .country-suggest {
    .bp3-icon {
      top: 6px;
      left: 3px;
      opacity: 0.8;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    input[type="text"].bp3-input {
      font-size: 1rem;
      font-weight: 500;
      padding: 6px 10px 6px 34px;
      width: 300px;
    }
  }

  .explore-section__featured-countries {
    margin-top: 35px;

    h3 {
      color: ${props => props.theme.colorGrayDark};
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  .featured-countries__wrapper {
    display: flex;

    .featured-country:last-of-type {
      margin-right: 0;
    }

    @media screen and (max-width: 500px) {
      flex-wrap: wrap;

      .featured-country {
        flex: 0 calc(50% - 10px);
        margin-bottom: 1rem;

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
`

const StyledCountryCard = styled(Link)`
  margin-right: 20px;
  height: 100px;
  width: 62.5%;
  background-image: url(${props => props.pic});
  background-position: 50% 50%;
  background-size:cover;
  position: relative;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: space-between;
  align-items: flex-end;
  transition: all 120ms ease-in-out;
  
  h6 {
    color: #fff;
    font-family: ${props => props.theme.fontHeading};
    z-index: 2;
    font-size: 1.44rem;
    letter-spacing: -0.5px;
    margin: 0;
    line-height: 1em;
    text-shadow: 0 0 2px #000;
  }

  .bp3-icon {
    color: #fff;
    z-index: 2;
    position: relative;
    top: -1px;
    transition: all 120ms ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, transparent 40%);
    z-index: 1;
    border-radius: 8px;
  }

  @media (pointer: fine) and (hover: hover) {
    &:hover {
      text-decoration: none;
      transform: scale(1.05);

      .bp3-icon {
        transform: translateX(3px);
      }
    }
  }
`

class ExploreSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ''
    }
    const chooser = randomNoRepeats(featuredCountries);
    this.featuredCountries = [];
    this.featuredCountries.push(chooser());
    this.featuredCountries.push(chooser());
    this.featuredCountries.push(chooser());
    this.featuredCountries.push(chooser());

    // Remove unwanted countries from dropdown
    const unwantedCountries = ['US', 'AQ', 'TF', 'BV', 'HM', 'GS']
    this.countryItems = countryItems.filter(countryItem => {
      return !unwantedCountries.includes(countryItem.code)
    });
  }

  render() {
    return (
      <StyledExploreSection tall={this.props.tall}>
        <div className="explore-section__wrapper">
          <div className="explore-section__country-suggest">
            <h2>Find a destination and we&apos;ll prepare you for your trip</h2>
            <Suggest
              inputProps={{
                className: "country-suggest",
                placeholder: "Search for a country",
                autoComplete: "new-password",
                value: this.state.inputValue,
                autoFocus: true,
                leftIcon: "map-marker"
              }}
              inputValueRenderer={item => {
                //console.log('inputvaluerenderer: ', item);
                return this.state.inputValue;
              }}
              query={this.state.inputValue}
              onQueryChange={query => {
                this.setState({
                  inputValue: query
                });
              }}
              itemPredicate={filterCountry}
              openOnKeyDown
              popoverProps={{
                portalContainer: document.getElementById("ground-container"),
                position: "bottom"
              }}
              items={this.countryItems}
              itemRenderer={renderCountry}
              noResults={<MenuItem disabled={true} text="No results." />}
              onItemSelect={(item, e) => {this.props.history.push(`/country/${item.slug}`)}}
            />
          </div>
          <div className="explore-section__featured-countries">
            <h3>Popular Countries</h3>
            <div className="featured-countries__wrapper">
              {this.featuredCountries.map((country, countryIndex) => {
                return (
                  <StyledCountryCard key={`featured-country-${countryIndex}`} className="featured-country" pic={country.pic} to={`/country/${country.slug}`}>
                    <h6>{country.name}</h6>
                    <Icon icon="caret-right" />
                  </StyledCountryCard>
                )
              })}
            </div>
          </div>
        </div>
      </StyledExploreSection>
    );
  }
}

export default withRouter(ExploreSection);