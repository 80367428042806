import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../shared/Logo';

const StyledGate = styled.div`
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .gate__wrapper {
    max-width: 380px;
  }

  .gate__content {
    h2 {
      font-weight: 500;
      margin-bottom: 10px;;
    }

    p {
      margin-bottom: 10px;
      color: ${props => props.theme.colorGrayDarker};
    }
  }

  .gate__actions {
    margin-top: 20px;
  }

  .gate__logo {
    svg {
      width: 150px;
      margin-bottom: 15px;
    }
  }
`;

class Gate extends Component {
  render() {
    return (
      <StyledGate>
        <div className="gate__wrapper">
          <div className="gate__logo">
            <Logo />
          </div>
          <div className="gate__content">
            {this.props.children}
          </div>
        </div>
      </StyledGate>
    )
  }
}

export default Gate;