// Third party CSS styles
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';

import React from 'react';
import { hydrate, render } from "react-dom";
import { BrowserRouter as Router } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { Authenticator } from 'aws-amplify-react/lib/Auth'
import { ApolloProvider as Provider } from 'react-apollo';
import AWSAppSyncClient from 'aws-appsync';
import { Rehydrated } from 'aws-appsync-react'
import { unregister } from './registerServiceWorker';
import App from './App';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { lighten, darken } from 'polished';
import { Helmet } from "react-helmet";
import { initialize } from 'minimal-analytics';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from './components/shared/MixpanelContext';

// Colors
const colors = {
  colorGray: '#AFBCBD',
  colorGrayDark: '#849496',
  colorGrayDarker: '#586667',
  colorGrayDarkest: '#334042',
  colorGrayLight: '#D5DEDE',
  colorGrayLighter: '#EFF4F4',
  colorBlack: '#162325',
  colorPink: '#E36E9F',
  colorPinkDark: '#D74482',
  colorPinkDarker: '#871142',
  colorPinkLight: '#EFB1CB',
  colorPinkLighter: '#f7edf2',
  colorOrange: '#FC8856',
  colorOrangeDark: '#E84E0D',
  colorOrangeDarker: '#AC4013',
  colorOrangeLight: '#FBD2C0',
  colorOrangeLighter: '#F8EDE8',
  colorYellow: '#F1CB77',
  colorYellowDark: '#D9A93E',
  colorYellowDarker: '#906B18',
  colorYellowLight: '#FFECC2',
  colorYellowLighter: '#F6F2EA',
  colorGreen: '#62DF9C',
  colorGreenDark: '#2CBD6F',
  colorGreenDarker: '#096A36',
  colorGreenLight: '#B2F3D0',
  colorGreenLighter: '#E8F8EF',
  colorTeal: '#65C9D9',
  colorTealDark: '#0A94AA',
  colorTealDarker: '#135762',
  colorTealDarkest: '#103940',
  colorTealLight: '#ABE4ED',
  colorTealLighter: '#E6F3F5',
  colorBlue: '#7096E8',
  colorBlueDark: '#3364CD',
  colorBlueDarker: '#163474',
  colorBlueLight: '#B2C7F4',
  colorBlueLighter: '#EBEEF7',
  colorPurple: '#AA70DE',
  colorPurpleDark: '#793BAF',
  colorPurpleDarker: '#4A127B',
  colorPurpleLight: '#D4B7EE',
  colorPurpleLighter: '#F1E9F7',
  colorRed: '#E46C6C',
  colorRedDark: '#E03131',
  colorRedDarker: '#A01C1C',
  colorRedLight: '#F0C6C6',
  colorRedLighter: '#F8EEEE',
  colorBeige: '#DEC09B',
  colorBeigeDark: '#C19F74',
  colorBeigeDarker: '#836E53',
  colorBeigeLight: '#EBDECE',
  colorBeigeLighter: '#F7F2EC',
  colorBlush: '#f3d7cf',
  colorBlushLight: '#f7e8e4',
}

// Shared CSS theme variables
const theme = {
  fontSans: "acumin-pro, sans-serif",
  fontHeading: "acumin-pro-extra-condensed, sans-serif",
  ...colors,
  colorPrimary: colors.colorTealDarker,
  bp: {
    guide: '600px',
    pack: '520px',
    dialog: '480px',
    timeline: '635px'
  }
}

const GlobalStyle = createGlobalStyle`
  // For styles that can occur outside of the app, like dialogs

  /* HTML tag styling */
  html {
    background-color: ${props => props.theme.colorTealLighter};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // Typography
  a, .fake-link {
    color: ${props => props.theme.colorTealDarker};
    cursor: pointer;

    @media (pointer: fine) and (hover: hover) {
      &:hover {
        color: ${props => props.theme.colorTealDark};
      }
    }

    &.link-button {
      position: relative;

      &:hover {
        text-decoration: none;
      }

      &.--fill {
        width: 100%;
      }
    }

    &.a--basic {
      &:hover {
        text-decoration: none;
      }
    }

    .bp3-icon {
      vertical-align: baseline !important;
    }
  }
  

  /* Copy & Lists */
  p {
    line-height: 1.5em;

    a {
      font-weight: 500;
    }
  }

  ul li,
  ol li {
    line-height: 1.4em;
  }

  ul ul,
  ol ul,
  ul ol,
  ol ol {
    margin-top: 0;
    margin-bottom: 0;
  }

  blockquote {
    line-height: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    border-left: 1px solid lighten($gray, 15%);
    padding-left: 1rem;
    font-size: 1.2rem;
  }

  /* Headings */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    line-height: 1.4em;
    color: #333;
  }

  // Type scale: 1.2 minor third
  // Covers
  h1 {
    font-size: 2.074rem;
    font-weight: 700;
  }

  // Section headers, including About
  h2 {
    font-size: 1.44rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }

  // Item or card heading
  h3 {
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 4px;
  }

  // Item subheading
  h4 {
    font-size: 1.1rem;
    font-weight: 400;
    color: #242424;
    margin-top: 0;
    margin-bottom: 0.75rem;
    line-height: 1.3em;
    font-weight: 500;
  }
  
  // To-do header
  h5 {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  // Smallcap section headers
  h6 {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #333;
    margin-top: 1rem;
    margin-bottom: 6px;
  }

  /* Custom classes */
  .text--gray-darker {
    color: ${props => props.theme.colorGrayDarker};
  }

  .text--gray-dark {
    color: ${props => props.theme.colorGrayDark};
  }

  .text__small-cap {
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
    font-weight: 500;

    &.text__small-cap--m {
      font-size: 14px;
    }
  }

  .p-20 {
    padding: 20px;
  }

  .divider {
    height: 1px;
    margin: 1rem auto;
    background-color: rgba(0,0,0,0.2);
  }

  .divider--s {
    width: 30px;
  }

  .divider--m {
    width: 60px;
  }

  .divider--fill {
    width: 100%;
  }

  .divider--left {
    margin-left: 0;
    margin-right: 0;
  }

  .divider--thick {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .help-bubble {
    display: inline-block;
    opacity: 0.5;
    cursor: pointer;
    font-size: 0.7em;
    position: relative;
    top: -2px;
  }

  // Tag
  .tag {
    font-weight: 600;
    font-size: 12px;
    padding: 0 6px 2px;
    border-radius: 2px;
    cursor: default;
    background-color: ${props => props.theme.colorGrayLighter};
    color: ${props => props.theme.colorGrayDarker};
    position: relative;
    top: -1px;
    white-space: nowrap;
    margin-right: 5px;

    &.tag--block {
      display: inline-block;
      line-height: 1.6em;
      margin-bottom: 3px;
      padding-bottom: 1px;
    }

    .bp3-icon {
      position: relative;
      top: -1px;
    }

    &.tag--red {
      background-color: ${props => props.theme.colorRedLighter};
      color: ${props => props.theme.colorRedDark};
    }

    &.tag--red-invert {
      background-color: ${props => props.theme.colorRedDark};
      color: #fff;
    }

    &.tag--green-invert {
      background-color: ${props => props.theme.colorGreenDark};
      color: #fff;
    }

    &.tag--yellow {
      background-color: ${props => props.theme.colorYellowLight};
      color: ${props => props.theme.colorYellowDarker};
    }

    &.tag--yellow-invert {
      background-color: ${props => props.theme.colorYellowDarker};
      color: #fff;
    }

    &.tag--teal {
      background-color: ${props => props.theme.colorTealLighter};
      color: ${props => props.theme.colorTealDarker};
    }

    &.tag--teal-invert {
      background-color: ${props => props.theme.colorTealDarker};
      color: #fff;
    }

    a & {
      cursor: pointer;
      transition: all 120ms ease-in-out;

      &:hover {
        text-decoration: none;
        opacity: 0.9;
      }
    }
  }

  .note {
    padding: 10px;
    margin-bottom: 15px;
    cursor: default;

    &.note--red {
      background-color: ${props => props.theme.colorRed};
      color: #fff;
    }

    &.note--pink {
      background-color: ${props => props.theme.colorPinkDark};
      color: #fff;
    }

    &.note--yellow {
      background-color: ${props => props.theme.colorYellowDark};
      color: #fff;
    }

    &.note--yellow-inverted {
      color: ${props => props.theme.colorYellowDarker};
      background-color: ${props => props.theme.colorYellowLight};
    }

    &.note--green {
      background-color: ${props => props.theme.colorGreenDark};
      color: #fff;
    }

    .bp3-icon {
      margin-right: 5px;
    }
  }

  .note-action {
    color: #fff;
    padding: 15px;
    width: 100%;
    margin: 0 auto -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0 1.5rem 0 0;
      text-align: left;
    }

    .link-button {
      display: block;
      min-width: 100px;

      .bp3-button {
        width: 100%;
      }
    }

    .bp3-button {
      min-width: 100px;
    }

    @media screen and (max-width: 460px) {
      flex-direction: column;

      p {
        margin: 0 0 10px 0;
      }

      .bp3-button, .link-button {
        width: 100%;
        max-width: none;
        min-width: none;
      }
    }

    &.note-action--green {
      background-color: ${props => props.theme.colorGreenDarker};

      .bp3-button {
        &.bp3-intent-primary {
          background-color: ${props => darken(0.15, props.theme.colorGreenDarker)};

          @media (pointer: fine) and (hover: hover) {
            &:hover {
              background-color: ${props => darken(0.1, props.theme.colorGreenDarker)};
              box-shadow: none;
            }
          }
        }
      }
    }

    &.note-action--pink {
      background-color: ${props => props.theme.colorPinkDarker};

      .bp3-button {
        &.bp3-intent-primary {
          background-color: ${props => darken(0.15, props.theme.colorPinkDarker)};

          @media (pointer: fine) and (hover: hover) {
            &:hover {
              background-color: ${props => darken(0.1, props.theme.colorPinkDarker)};
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  // Color symbols
  .color-symbol {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 2px;
    border-radius: 5px;
  }

  .color-symbol--red {
    background-color: ${props => props.theme.colorRedDark};
  }

  .color-symbol--red-dark {
    background-color: ${props => props.theme.colorRedDarker};
  }

  .color-symbol--yellow {
    background-color: ${props => props.theme.colorYellowDark};
  }

  .color-symbol--green {
    background-color: ${props => props.theme.colorGreenDark};
  }

  .color-symbol--blue {
    background-color: ${props => props.theme.colorBlue};
  }

  .color-symbol--blue-dark {
    background-color: ${props => props.theme.colorBlueDark};
  }

  // Metadata group
  .meta-group {
    display: flex;
    flex-direction: column;
    cursor: default;
  }

  .meta-group__value {
    font-size: 18px;
    font-weight: 600;
    position: relative;
    white-space: nowrap;
    margin-bottom: 3px;

    .color-symbol {
      position: relative;
      top: -2px;
      margin-right: 0;
    }

    &.--small {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .meta-group__attr {
    font-size: 13px;
    color: ${props => props.theme.colorGrayDarker};
    font-weight: 500;
    text-transform: capitalize;
  }

  .meta-group__unit {
    font-size: 0.8rem;
    font-weight: 400;
    margin-left: 3px;
    position: relative;
    top: -3px;
    color: ${props => props.theme.colorGrayDark};
  }

  // Newly added element highlight
  .highlight--yellow {
    animation-name: yellowFade;
    animation-duration: 2.3s;
  }

  @keyframes yellowFade {
      from { background: ${props => props.theme.colorYellow}; }
      to { background: #fff; }
  }

  /* Blueprint overrides */
  // Buttons
  .bp3-button {
    padding: 10px 12px;
    border-radius: 2px;
    border: none;
    font-weight: 600;
    transition: all 120ms ease-in-out;
    background-color: ${props => props.theme.colorGrayLight};
    color: #333;
    position: relative;

    &:hover {
      background-color: ${props => props.theme.colorGray};
    }

    .bp3-icon svg {
      height: 14px;
    }

    > .bp3-button-text {
      transition: all 120ms ease-in-out;

      // For right icon only
      ~.bp3-icon {
        margin: 0 -5px 0 -6px;
        position: relative;
        top: 1px;
      }
    }

    &.bp3-minimal {
      background: none;

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          background-color: ${props => props.theme.colorGrayLight};
        }
      }
    }

    &.bp3-small {
      min-height: 24px;
      font-size: 12px;
    }

    // Primary button
    &.bp3-intent-primary {
      background-color: #333;
      color: #fff;
      transition: all 120ms ease-in-out;

      &:hover {
        background-color: #333;
        box-shadow: none;
      }

      &.bp3-active {
        background-color: ${props => lighten(0.07, props.theme.colorTealDarker)};
        box-shadow: none;
      }

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          background-color: ${props => lighten(0.07, props.theme.colorTealDarker)};
        }
      }

      &.bp3-disabled {
        background-color: ${props => props.theme.colorGrayDark};
        opacity: 0.4;

        &:hover {
          background-color: ${props => props.theme.colorGrayDark};
        }
      }
    }

    // Secondary button
    &.bp3-intent-secondary {
      transition: all 120ms ease-in-out;
      background: none;
      box-shadow: 0 0 0 1px ${props => props.theme.colorGray};
      color: rgba(0,0,0,0.7);

      &.bp3-active {
        box-shadow: 0 0 0 1px ${props => props.theme.colorTealDarker};
        color: #fff;
        background-color: ${props => props.theme.colorTealDarker};

        @media (pointer: fine) and (hover: hover) {
          &:hover {
            box-shadow: 0 0 0 1px ${props => props.theme.colorTealDarker};
            color: #fff;
            background-color: ${props => props.theme.colorTealDarker};
          }
        }
      }

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          box-shadow: 0 0 0 1px ${props => props.theme.colorTealDarker};
          background-color: ${props => props.theme.colorTealLighter};
          color: ${props => props.theme.colorTealDarker};
        }
      }
      
      &.bp3-disabled {
        box-shadow: 0 0 0 1px ${props => props.theme.colorGray};
        opacity: 0.4;

        @media (pointer: fine) and (hover: hover) {
          &:hover {
            box-shadow: 0 0 0 1px ${props => props.theme.colorGray};
            background: none;
            color: rgba(0,0,0,0.7);
            opacity: 0.4;
          }
        }
      }

      .bp3-icon {
        opacity: 0.75;
      }
    }

    // Tertiary button
    &.bp3-intent-tertiary {
      color: ${props => props.theme.colorPrimary};
      background: none;
      padding: 3px 0;

      .bp3-button-text {
        position: relative;
      }

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          //color: ${props => lighten(0.1, props.theme.colorPrimary)};

          &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            left: 50%;
            bottom: -1px;
            transform: translateX(-50%);
            background-color: ${props => props.theme.colorPrimary};
          }
        }
      }
    }

    &.bp3-dialog-close-button {
      padding: 5px 10px;

      .bp3-icon svg {
        width: 18px;
        height: 18px;
      }
    }

    .bp3-icon {
      margin-right: 4px;

      svg {
        //width: 14px;
        //height: 14px;
      }
    }

    &.btn--fill-space-between {
      // For buttons that are full width but with a select icon that needs to be spread out
      align-items: center;
      justify-content: space-between;
    }
  }

  // Remove default background and box shadow
  .bp3-button, .bp3-button.bp3-intent-primary, .bp3-button:not([class*="bp3-intent-"]) {
    background-image: none;
    box-shadow: none;

    &:hover {
      border: none;
      box-shadow: none;
    }
  }

  // Button group
  .bp3-button-group:not(.bp3-minimal) > .bp3-popover-wrapper:not(:last-child) .bp3-button, .bp3-button-group:not(.bp3-minimal) > .bp3-button:not(:last-child) {
    margin-right: 1px;
  }

  // Select
  .bp3-select-popover {
    .bp3-popover-content {
      padding: 5px 0;
    }
  }

  .form-popover--fill {
    .bp3-popover-wrapper, .bp3-popover-target, input {
      width: 100%;
    }
  }

  // Regular select
  .select-minimal {
    margin-left: 10px;

    select {
      box-shadow: none;
      border: 1px solid ${props => props.theme.colorGray};
      background-color: #fff;
      font-size: 13px;
      border-radius: 2px;
      line-height: 1.5em;
      transition: all 120ms ease-in-out;
      background-image: none;

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          border-color: ${props => props.theme.colorTealDarker};
          background-color: ${props => props.theme.colorTealLighter};
          color: ${props => props.theme.colorTealDarker};
          box-shadow: none;
        }
      }
    }
  }

  // Button turned into fake link
  .button-link {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    color: ${props => props.theme.colorPrimary};

    @media (pointer: fine) and (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }

    &.button-link--small {
      font-size: 12px;
    }
  }

  // For inputs with a button, like clear country
  .bp3-input-action {
    height: 100%;

    .bp3-popover-wrapper, .bp3-popover-target {
      height: 100%;
    }

    .bp3-button {
      padding: 0 7px;
      height: 100%;
      margin: 0;
      min-width: 28px;
    }
  }

  // Menu items
  .bp3-menu {
    padding: 5px 0;
    border-radius: 0;
  }

  a.bp3-menu-item {
    border-radius: 0;
    padding: 5px 12px;

    @media (pointer: fine) and (hover: hover) {
      &:hover {
        color: #000;
        background-color: ${props => props.theme.colorGrayLight};

        .bp3-menu-item-label {
          //color: rgba(255,255,255,0.6);
          color: #555;
        }
      }
    }

    > .bp3-fill { 
      min-width: 90px;
    }

    > .bp3-menu-item-label {
      // Create ellipsis on left side
      overflow: hidden;
      text-overflow: ellipsis;
      direction: rtl;
      max-width: calc(100% - 95px);
      white-space: nowrap;
      text-align: right;
    }
  }

  .bp3-menu-divider {
    margin: 5px 0;
    border-top: 1px solid ${props => props.theme.colorGrayLighter};
  }

  // Default popover
  .bp3-popover {
    border-radius: 0;
    box-shadow: 0 8px 24px rgba(16, 22, 26, 0.2);

    .bp3-popover-arrow-border {
      fill: ${props => props.theme.colorGrayLight};
    }

    // Special version with powered by google
    &.powered-by {
      .bp3-menu {
        margin-bottom: 30px;
        border-bottom: 1px solid ${props => props.theme.colorGrayLighter};
        padding-bottom: 5px;

        &:after {
          content: '';
          background: url('/powered_by_google.png');
          background-repeat: no-repeat;
          background-size: 100% 12px;
          height: 24px;
          position: absolute;
          right: 10px;
          bottom: 0;
          width: 96px;
        }
      }
    }
  }

  // Toast
  .bp3-toast-container {
    z-index: 300;
  }

  .toaster {
    .bp3-toast {
      background-color: ${props => props.theme.colorGrayDarkest};
      color: #fff;
      box-shadow: none;
      font-weight: 600;
      z-index: 300; // Middle z-index level

      .bp3-button {
        padding: 0;

        .bp3-icon {
          color: ${props => props.theme.colorGray};
        }
      }
    }
  }

  // Marketing pages
  .shape {
    font-size: 15px;
    display: inline-block;
    background-color: ${props => props.theme.colorGrayDarker};
    border-color: ${props => props.theme.colorGrayDarker};
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  } 

  .shape--m {
    font-size: 30px;
  }

  .shape--l {
    font-size: 45px;
  }

  .shape--xl {
    font-size: 70px;
  }

  .with-shape {
    display: flex;
    align-items: center;

    .shape {
      margin-right: 8px;

      &.parallelogram {
        margin: 0 11px 0 2px;
      }
    }
  }

  .square {
    width: 1em;
    height: 1em;
  }

  .circle {
    width: 1em;
    height: 1em;
    border-radius: 50%;
  }

  .triangle {
    width: 1.05em;
    height: 1.05em;
    background: none;
    position: relative;

    // Made it a pseudo element so that it can inherit the color of parent
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      border-left: 0.55em solid transparent;
      border-right: 0.55em solid transparent;
      border-bottom: 1.1em solid;
      border-bottom-color: inherit;
    }
  }

  .parallelogram {
    width: 0.8em;
    height: 1em;
    transform: skew(27deg);
  }
`

// Base style sheet for the entire app
const BaseStyles = styled.div`
  // Generic classes
  font-family: ${theme.fontSans};
  font-weight: 400;
  background-color: ${props => props.theme.colorTealLightest};

  // Helper classes
  .ml-10 {
    margin-left: 10px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .bg-teal-dark {
    background-color: ${props => props.theme.colorTealDark};
  }

  .bg-yellow-dark {
    background-color: ${props => props.theme.colorYellowDark};
  }

  .bc-yellow-dark {
    border-color: ${props => props.theme.colorYellowDark};
  }

  .bg-orange-dark {
    background-color: ${props => props.theme.colorOrangeDark};
  }

  .bc-orange-dark {
    border-color: ${props => props.theme.colorOrangeDark};
  }

  .bg-purple-dark {
    background-color: ${props => props.theme.colorPurpleDark};
  }

  .bg-pink-dark {
    background-color: ${props => props.theme.colorPinkDark};
  }

  .bg-blue-dark {
    background-color: ${props => props.theme.colorBlueDark};
  }

  .bc-blue-dark {
    border-color: ${props => props.theme.colorBlueDark};
  }

  .bg-green-dark {
    background-color: ${props => props.theme.colorGreenDark};
  }

  .bg-beige-dark {
    background-color: ${props => props.theme.colorBeigeDark};
  }

  // UI classes
  .tooltip {
    max-width: 300px;

    .bp3-popover-content {
      background-color: ${props => props.theme.colorGrayDarkest};
      color: #fff;
      border: none;
      font-weight: 600;
    }

    .bp3-popover-arrow-fill {
      fill: ${props => props.theme.colorGrayDarkest};
    }

    &.tooltip--red {
      .bp3-popover-content {
        background-color: ${props => props.theme.colorRedDark};
      }
      .bp3-popover-arrow-fill {
        fill: ${props => props.theme.colorRedDark};
      }
    }
  }

  // Markdown
  .md {
    margin: -5px 0 -10px;
    display: inline-block;
    width: 100%;

    a {
      padding: 2px 0 1px;
      border-radius: 1px;
      text-decoration: underline;
    }

    p {
      margin-top: 5px;

      strong {
        font-weight: 600;
        color: #000;
      }
    }

    ul, ol {
      padding-left: 19px;
      margin-top: 5px;

      li:first-of-type {
        padding-top: 0;
      }
    }

    li {
      padding-top: 5px;
      line-height: 1.5em;

      > ul, > ol {
        margin-bottom: 0;
      }
    }

    &.--spaced {
      h3 {
        margin-bottom: 10px;
        margin-top: 22px;
      }
    }

    h5 {
      margin-top: 0;
    }
  }

  /* Transition */
  // Vertical enter/fade
  .fade-enter, .fade-appear {
    opacity: 0;
    transform: translateY(-30px);
  }

  .fade-enter-active, .fade-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 250ms;
  }

  .fade-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .fade-exit-active {
    opacity: 0;
    transform: translateY(30px);
    transition: all 250ms;
  }

  // Horizontal enter/fade
  .horiz-fade-enter {
    opacity: 0;
    transform: translateX(-30px);

    &:after {
      opacity: 0;
    }
  }

  .horiz-fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 150ms ease-in-out;

    &:after {
      opacity: 1;
      transition: all 150ms ease-in-out;
    }
  }

  .horiz-fade-exit {
    opacity: 1;
    transform: translateX(0);

    &:after {
      opacity: 1;
    }
  }

  .horiz-fade-exit-active {
    opacity: 0;
    transform: translateX(30px);
    transition: all 250ms;

    &:after {
      opacity: 0;
    }
  }
  

  // To move into components
  svg.bp3-icon {
    vertical-align: sub;
  }

  // Icons
  .bp3-icon {
    vertical-align: middle;
  }

  // Forms
  .bp3-control-group {
    > :first-child {
      input {
        border-radius: 2px 0 0 2px;
      }
    }
    > :last-child {
      input {
        border-radius: 0 2px 2px 0;
      }
    }
  }
  
  .bp3-form-group {
    margin: 0 0 20px;
    position: relative;

    label.bp3-label {
      font-weight: 600;

      .bp3-text-muted {
        font-weight: 400;
        font-size: 13px;
        color: ${props => props.theme.colorGrayDarker};
      }
    }
  }

  .bp3-input {
    box-shadow: none;
    border: 1px solid ${props => props.theme.colorGray};
    border-radius: 2px;
    height: auto;
    padding: 0 7px;

    &:focus, &.bp3-active {
      border-color: ${props => props.theme.colorTealDark};
      outline: none;
      outline-style: none;
      box-shadow: none;
    }

    &:disabled {
      cursor: default;
      user-select: none;
      background-color: ${props => props.theme.colorGrayLight};
      border-color: ${props => props.theme.colorGrayLight};
      color: ${props => props.theme.colorGrayDarkest};
      -webkit-text-fill-color: ${props => props.theme.colorGrayDarkest};  // Fix for iOS safari color
      opacity: 1;
      font-weight: 500;
      
      &::placeholder {
        color: ${props => props.theme.colorGrayDark};
      }
    }

    &::placeholder {
      color: ${props => props.theme.colorGrayDark};
    }

    &.bp3-input--fill {
      width: 100%;
    }
  }

  .bp3-input.bp3-intent-danger, .bp3-input-group.bp3-intent-danger .bp3-input {
    border-color: ${props => props.theme.colorRedDark};
    box-shadow: none;

    &:focus {
      outline: none;
      outline-style: none;
      box-shadow: none;
    }
  }

  textarea.bp3-input {
    padding: 7px;
  }

  // Date picker for Trip Create
  .bp3-datepicker {
    .DayPicker-Day {
      border-radius: 0;
      font-weight: 500;
      color: #333;
      position: relative;

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          background-color: ${props => props.theme.colorGrayDarkest};
          color: #fff;
        }
      }

      &.DayPicker-Day--outside {
        color: ${props => props.theme.colorGrayDark};
        
        @media (pointer: fine) and (hover: hover) {
          &:hover {
            color: #fff;
          }
        }
      }

      &.DayPicker-Day--selectedRange {
        background-color: ${props => props.theme.colorGrayLight};
        color: #333;
        font-weight: 500;

        /* &.DayPicker-Day--disabled {
          background-color: #fff;
        } */

        @media (pointer: fine) and (hover: hover) {
          &:hover {
            color: #333;
            background-color: ${props => props.theme.colorGray};
          }
        }
      }

      &[class*=' DayPicker-Day--selected-dates-'] {
        background-color: ${props => props.theme.colorGrayDarker};
        color: #fff;
        font-weight: 700;
        opacity: 1 !important;

        &.DayPicker-Day--disabled {
          color: #fff;

          &:after {
            content: '';
          }
        }

        @media (pointer: fine) and (hover: hover) {
          &:hover {
            background-color: ${props => props.theme.colorGrayDarker};
            color: #fff;
          }
        }
      }

      &.DayPicker-Day--selected {
        background-color: ${props => props.theme.colorGrayDarkest};
        color: #fff;
        font-weight: 700;
        opacity: 1 !important;

        @media (pointer: fine) and (hover: hover) {
          &:hover {
            background-color: ${props => props.theme.colorGrayDarkest};
            color: #fff;
          }
        }
      }
      
      &.DayPicker-Day--disabled {
        opacity: 0.5;
        color: ${props => props.theme.colorGrayDark};
        pointer-events: none;

        @media (pointer: fine) and (hover: hover) {
          &:hover {
            background-color: transparent;
            color: ${props => props.theme.colorGrayDark};
          }
        }

        &:after {
          content: '×';
          font-size: 2rem;
          line-height: 0;
          color: rgba(0,0,0,0.2);
          position: absolute;
          top: 11px;
          left: 5.5px;
          pointer-events: none;
        }
      }
    }
   
    .DayPicker-Day--0, .DayPicker-Day--4, .DayPicker-Day--8, .DayPicker-Day--12, .DayPicker-Day--16, .DayPicker-Day--20 {
      background-color: ${props => darken(0.06, props.theme.colorPinkLighter)};
      color: ${props => props.theme.colorPinkDark};
    }

    .DayPicker-Day--1, .DayPicker-Day--5, .DayPicker-Day--9, .DayPicker-Day--13, .DayPicker-Day--17 {
      background-color: ${props => darken(0.06, props.theme.colorYellowLighter)};
      color: ${props => darken(0.1, props.theme.colorYellowDark)};
    }

    .DayPicker-Day--2, .DayPicker-Day--6, .DayPicker-Day--10, .DayPicker-Day--14, .DayPicker-Day--18 {
      background-color: ${props => darken(0.06, props.theme.colorGreenLighter)};
      color: ${props => props.theme.colorGreenDark};
    }

    .DayPicker-Day--3, .DayPicker-Day--7, .DayPicker-Day--11, .DayPicker-Day--15, .DayPicker-Day--19 {
      background-color: ${props => darken(0.06, props.theme.colorPurpleLighter)};
      color: ${props => props.theme.colorPurpleDark};
    }

    .DayPicker-Day--today {
      font-weight: 600;
      position: relative;
      z-index: 0;

      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: 6px;
        left: 4.5px;
        border-radius: 100%;
        background-color: ${props => props.theme.colorGrayLight};
        opacity: 0.6;
        pointer-events: none;
        z-index: -1;
      }
      
      @media (pointer: fine) and (hover: hover) {
        &:hover {
          &:after {
            opacity: 0;
          }
        }
      }
    }
  }

  .form-group__descr {
    margin: 0 0 15px;
    color: ${props => props.theme.colorGrayDarker};
  }
  
  // For select menu active item
  .bp3-menu-item.bp3-intent-primary:hover, .bp3-submenu .bp3-popover-target.bp3-popover-open > .bp3-intent-primary.bp3-menu-item, .bp3-menu-item.bp3-intent-primary.bp3-active {
    background-color: ${props => props.theme.colorTealDarker};
  }

  .bp3-select-popover, .bp3-multi-select-popover {
    .bp3-menu {
      max-width: 310px;
      max-height: 200px;
      overflow-x: hidden;
    }

    .bp3-input-group {
      padding: 0 5px 5px;
      background: #fff;
      margin-bottom: -5px;

      .bp3-icon {
        left: 5px;
      }
    }
  }

  .bp3-control input:checked ~ .bp3-control-indicator, .bp3-control:hover input:checked ~ .bp3-control-indicator {
    background-color: ${props => props.theme.colorTealDarker};
  }

  textarea.bp3-input {
    width: 100%;
  }

  .bp3-progress-bar {
    //border-radius: 0;

    .bp3-progress-meter {
      //border-radius: 0;
    }

    &.bp3-intent-success {
      .bp3-progress-meter {
        background-color: ${props => props.theme.colorGreenDark};
      }
    }
  }

  .bp3-running-text {
    ul, ol {
      padding-left: 17px;
    }
  }

  .wrapper {
    display: flex;
    height: 100vh;
  }

  table.bp3-html-table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;

    tbody tr:first-child {
      border-top: none;

      td {
        box-shadow: none;
      }
    }

    tr {
      border-top: 1px solid ${props => props.theme.colorGrayLighter};
    }

    td {
      padding: 16px 15px;
    }
  }

  .content {
    flex: 1 1 auto;
    overflow: auto;
  }
  
  // User attribute edit forms
  .user-attr__intro {
    margin-top: -5px;
  }

  .user-attr__title {
    font-weight: 700;
    font-size: 17px;
    padding: 5px 0 10px;
  }

  .user-attr__descr {
    color: #777;
    font-size: 13px;
    margin-top: -5px;
  }

  .user-attr__reason {
    background-color: #f5f5f5;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 1.5em;
  }

  // User items
  .user-items__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 15px;

    h3 {
      .bp3-icon {
        opacity: 0.6;
        position: relative;
        top: -1px;
      }
    }

    p {
      color: ${props => props.theme.colorGrayDarker};
      margin-bottom: 0;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;

      .user-items__header-action, .bp3-button {
        width: 100%;
      }

      .bp3-button {
        margin: 10px 0 0 0;
      }
    }
  }

  .user-item {
    padding: 15px;
    background: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid ${props => props.theme.colorGrayLighter};
  }

  .user-item__content {
    margin-right: 20px;

    h3 {
      font-size: 15px;
    }
  }

  .user-item__descr {
    margin-bottom: 5px;
  }

  .user-item__meta {
    color: ${props => props.theme.colorGrayDark};
  }

  // Editable user rows
  .user-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 15px;
    background: #fff;
    color: #333;
    border-bottom: 1px solid ${props => props.theme.colorTealLighter};
    transition: all 120ms ease-in-out;

    @media (pointer: fine) and (hover: hover) {
      &:hover {
        text-decoration: none;
        background-color: ${props => props.theme.colorGrayLighter};

        .user-row__right .bp3-icon {
          transform: translateX(3px);
        }
      }
    }

    &.user-row--flat {
      @media (pointer: fine) and (hover: hover) {
        &:hover {
          text-decoration: none;
          background-color: #fff;
        }
      }
    }
  }

  .user-row__left {
    margin-right: 10px;
  }

  .user-row__right {
    display: flex;
    align-items: flex-end;
    color: ${props => props.theme.colorGrayDark};

    strong {
      color: #333;
    }

    .bp3-icon {
      margin-left: 10px;
      color: ${props => props.theme.colorPrimary};
      transition: all 120ms ease-in-out;
    }
  }

  // User account
  .account-edit {
    padding: 15px;
    background: #fff;
  }

  // Overlays (dialogs, popovers)
  #ground-container .bp3-portal {
    z-index: 100;
  }

  #sky-container .bp3-portal {
    z-index: 400;
  }

  #space-container .bp3-portal {
    z-index: 500;
  }

  .bp3-overlay.bp3-overlay-scroll-container {
    -webkit-overflow-scrolling: touch
  }

  .bp3-dialog {
    background-color: #fff;
    border-radius: 0;
    box-shadow: none;
    max-width: 500px;
    width: calc(100% - 10px);
    padding-bottom: 0;

    @media screen and (max-width: 500px) {
      margin: 5px 0;
    }

    &.dialog-recs--gear {
      max-width: 580px;
    }

    img {
      max-width: 100%;
    }
  }

  .bp3-dialog-footer {
    margin: 0 20px 20px;
  }

  .bp3-dialog-header {
    .bp3-heading {
      font-weight: 700;
    }
  }

  .dialog-action {
    padding: 20px;
    margin-top: 20px;
    border-top: 1px solid ${props => props.theme.colorGrayLight};
    display: none;

    @media screen and (max-width: 500px) {
      display: block;
    }
  }

  .popover--hidden {
    display: none;
  }

  // Auth styling
  .auth-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-top: -40px;
  }

  .auth-logo {
    margin-bottom: 10px;
    text-align: center;

    svg {
      width: 150px;
    }
  }

  .auth-header {
    text-align: center;
    margin: 15px 0 30px;

    h3 {
      font-size: 1.3rem;
      color: #333;
      font-weight: 400;
    }
  }

  .auth-form {
    background-color: #fff;
    padding: 20px;

    input {
      width: 100%;
    }
  }

  .auth-alt {
    margin-top: 25px;
    text-align: center;
    color: ${props => props.theme.colorGrayDark};
  }

  .auth-msg {
    text-align: center;
    padding: 10px;
    margin: 15px 0 25px;
    font-weight: 600;
  }

  .auth-msg--error {
    background-color: ${props => props.theme.colorRed};
    color: #fff;
  }

  .auth-msg--success {
    background-color: ${props => props.theme.colorGreenDark};
    color: #fff;
  }

  .form-label-link {
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 2px;
  }

  .sidebar-suggest {
    border-top: 1px solid ${props => props.theme.colorGrayLight};
    margin-top: 15px;
    padding-top: 15px;
    max-width: 180px;
    color: ${props => props.theme.colorGrayDark};
    font-size: 12px;
  }

  .suggest-card {
    background-color: ${props => props.theme.colorBeigeLight};
    color: ${props => darken(0.07, props.theme.colorBeigeDarker)};
    padding: 15px;
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin-bottom: 0;
      margin-right: 15px;
      font-weight: 600;
    }

    .bp3-button {
      background-color: ${props => darken(0.07, props.theme.colorBeigeDarker)};
      color: #fff;
      box-shadow: none;

      .bp3-button-text {
        white-space: nowrap;
      }

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          background-color: ${props => props.theme.colorBeigeDarker};
        }
      }
    }
  }

  // Rec local popover styling
  .rec-local__popover {
    padding: 15px;
    max-width: 250px;

    .rec-content {
      margin-bottom: 15px;

      h4 {
        font-size: 1rem;
        margin: -3px 0 3px 0;
        font-weight: 600;
      }
    }

    .rec-content__sentence {
      color: ${props => props.theme.colorGrayDarker};
      font-size: 13px;
      line-height: 1.3em;
    }

    .rec-link__price {
      font-weight: 400;
      opacity: 0.7;
      margin-left: 7px;
    }
  }

  // Masonry grid styling
  .masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }

  .masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  /* Style your items */
  .masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
  }
`

Auth.configure({
  region: process.env.REACT_APP_AWS_REGION,
  identityPoolId: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_COGNITO_IDENTITY_PROD : process.env.REACT_APP_COGNITO_IDENTITY_DEV,
  userPoolId: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_COGNITO_POOL_PROD : process.env.REACT_APP_COGNITO_POOL_DEV,
  userPoolWebClientId: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_COGNITO_CLIENT_PROD : process.env.REACT_APP_COGNITO_CLIENT_DEV,
  mandatorySignIn: true,
  cookieStorage: ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || window.location.hostname.indexOf('netlify.com') > 0) ? undefined : { domain: '.wanderium.com' }
});

API.configure({
  endpoints: [
    {
      name: "wanderiumFunctions",
      endpoint: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_LAMBDA_ENDPOINT_PROD : process.env.REACT_APP_LAMBDA_ENDPOINT_DEV,
      service: "lambda",
      region: process.env.REACT_APP_AWS_REGION
    }
  ]
});

// Set up AppSync GraphQL client
const clientAuthenticated = new AWSAppSyncClient({
  url: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_APPSYNC_URL_PROD : process.env.REACT_APP_APPSYNC_URL_DEV,
  region: process.env.REACT_APP_AWS_REGION,
  auth: {
    type: process.env.REACT_APP_APPSYNC_TYPE,
    jwtToken: async () => {
      try {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
      } catch (err) {
        // Nada
      }
    }
  },
  disableOffline: true
});

// Attempt at having Amplify not load its CSS file
const amplifyTheme = {
  container: null,
  formContainer: null,
  formSection: null,
  formField: null,

  sectionHeader: null,
  sectionBody: null,
  sectionFooter: null,
  sectionFooterPrimaryContent: null,
  sectionFooterSecondaryContent: null,

  input: null,
  button: null,
  photoPickerButton: null,
  photoPlaceholder: null,
  signInButton: null,
  signInButtonIcon: null,
  signInButtonContent: null,
  amazonSignInButton: null,
  facebookSignInButton: null,
  googleSignInButton: null,
  oAuthSignInButton: null,

  formRow: null,
  strike: null,
  strikeContent: null,
  actionRow: null,
  a: null,

  hint: null,
  radio: null,
  inputLabel: null,
  toast: null,

  navBar: null,
  nav: null,
  navRight: null,
  navItem: null,
  navButton: null
};

const AppRoot = () => (
  <ThemeProvider theme={theme}>
    <BaseStyles>
      <GlobalStyle />
      <Helmet titleTemplate="%s | Wanderium" defaultTitle="Wanderium">
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="description" content="Your personal, all-in-one travel assistant. Prepare for a trip in minutes, not hours." />
        <meta name="keywords" content="travel, travel tips, travel checklist, international travel checklist, travel reminders, international travel reminders, travel packing list, travel apps, travel gear, travel services, best travel gear, best travel apps, travel guide, international travel guide" />
        <meta property="og:title" content="Wanderium" />
        <meta property="og:description" content="Your personal, all-in-one travel assistant. Prepare for a trip in minutes, not hours." />
        <meta property="og:image" content='https://wanderium.s3-us-west-2.amazonaws.com/img/meta.png' />
        <meta property="og:url" content="https://www.wanderium.com" />
        <meta property="og:site_name" content="Wanderium" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@wanderium" />
        <meta name="twitter:image" content='https://wanderium.s3-us-west-2.amazonaws.com/img/meta.png' />
      </Helmet>
      <MixpanelProvider mixpanel={mixpanel}>
        <Provider client={clientAuthenticated}>
          <Rehydrated>
            <Router>
              <Authenticator hideDefault={true} theme={amplifyTheme}>
                <App />
              </Authenticator>
            </Router>
          </Rehydrated>
        </Provider>
      </MixpanelProvider>
      <div id="ground-container"></div>
      <div id="sky-container"></div>
      <div id="space-container"></div>
    </BaseStyles>
  </ThemeProvider>
)

// GA
initialize(window, process.env.NODE_ENV === 'production' ? process.env.REACT_APP_GA_PROD : process.env.REACT_APP_GA_DEV, {
  anonymizeIp: false,
  colorDepth: true,
  characterSet: true,
  screenSize: true,
  language: true
})

// Mixpanel
mixpanel.init(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_MIXPANEL_PROD : process.env.REACT_APP_MIXPANEL_DEV);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<AppRoot />, rootElement);
} else {
  render(<AppRoot />, rootElement);
}
unregister();
//window.LOG_LEVEL='DEBUG'; 