import React, { Component } from 'react';
import { FormGroup, Button } from "@blueprintjs/core";
import { Box } from 'reflexbox';
import { Formik } from 'formik';
import * as yup from 'yup';
import Auth from '@aws-amplify/auth';
import { withRouter, Link } from 'react-router-dom';
import Logo from '../shared/Logo';
import styled from 'styled-components';
import AngledBorder from '../shared/AngledBorder';
import { Helmet } from "react-helmet";

const StyledLegal = styled.p`
  font-size: 12px;
  color: ${props => props.theme.colorGrayDarker};
  margin: -5px 0 15px;
`

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email("Invalid email"),
  password: yup.string().required('Password is required'),
})

class SignUp extends Component {
  componentDidMount() {
    this.props.mixpanel.track('Sign Up Viewed');
    this.props.onStateChange('signUp', null);  // Reset authState and authData
  }

  handleSignUp = (values, actions) => {
    Auth.signUp(values.email.toLowerCase(), values.password)
      .then(resp => {
        //console.log('After sign up: ', resp);

        const { mixpanel } = this.props;
        mixpanel.track('Account Created');
        mixpanel.alias(resp.userSub);
        mixpanel.identify(resp.userSub);
        mixpanel.people.set({
          '$distinct_id': resp.userSub,
          '$email': values.email.toLowerCase(),
          '$created': new Date().toISOString(),
          'userType': 'FREE',
          'tripsCreated': 0,
          'onboarded': 'INCOMPLETE'
        });
        mixpanel.register({
          'userType': 'FREE',
          'tripsCreated': 0,
          'onboarded': 'INCOMPLETE'
        });

        //console.log('Email for onStateChange: ', values.email.toLowerCase());
        this.props.onStateChange('confirmSignUp', values.email.toLowerCase());  // Change user Auth state
        this.props.handleConfirmStore(values.email.toLowerCase(), values.password); // Temp save of credentials
        this.props.history.push('/confirm');  // Go to confirm page
      })
      .catch(err => {
        //console.log('sign up error: ', err);
        if (err.code === "UsernameExistsException") {
          // Try signing them in if it exists already
          Auth.signIn(values.email.toLowerCase(), values.password)
            .then(resp => {
              this.props.mixpanel.track('Logged In');
              this.props.mixpanel.identify(resp.username);
              //console.log('successful signin: ', resp);
              this.props.onStateChange('signedIn');
            })
            .catch(err => {
              if (err.code === "UserNotConfirmedException") {
                // If user isn't confirmed yet, resend code and redirect to confirm page
                Auth.resendSignUp(values.email.toLowerCase()).then(() => {
                  //console.log('code resent successfully');
                  this.props.onStateChange('confirmSignUp', values.email.toLowerCase());  // Change user Auth state
                  this.props.handleConfirmStore(values.email.toLowerCase(), values.password); // Temp save of credentials
                  this.props.history.push('/confirm');  // Go to confirm page
                }).catch(() => {
                  //console.log(err);
                });
              } else {
                //console.log('signin error: ', err);
                actions.setSubmitting(false);
                actions.setErrors({ general: err.message })
              }
            });
        } else {
          actions.setSubmitting(false);
          if (err.code === 'InvalidPasswordException') {
            actions.setErrors({ general: err.message.split('Password did not conform with policy: ')[1] });
          } else {
            actions.setErrors({ general: err.message });
          }
        }
      });
  }

  render() {
    //console.log("SignUp: ", this.props);

    if (this.props.authState === 'signedIn') {
      this.props.history.push('/');
    }

    return (
      <div className="auth-wrapper">
        <Helmet>
          <title>Sign Up</title>
        </Helmet>
        <Box w={270}>
          <div className="auth-logo">
            <Link to="/"><Logo /></Link>
          </div>
          <div className="auth-header">
            <h3>Let&#39;s sign you up</h3>
          </div>
          <AngledBorder>
            <div className="auth-form">
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={this.handleSignUp}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormGroup
                      label="Email"
                      labelFor="email"
                      helperText={errors.email && touched.email && errors.email}
                      intent={errors.email && touched.email && 'DANGER'}
                    >
                      <input
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email"
                        id="email"
                        className={`bp3-input ${errors.email && touched.email && 'bp3-intent-danger'}`}
                        autoComplete="new-password"
                        placeholder="beep@boop.com"
                        autoFocus
                      />
                    </FormGroup>
                    <FormGroup
                      label="Password"
                      labelFor="password"
                      helperText={errors.password && touched.password && errors.password}
                      intent={errors.password && touched.password && 'DANGER'}
                    >
                      <input
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        name="password"
                        id="password"
                        className={`bp3-input ${errors.password && touched.password && 'bp3-intent-danger'}`}
                        autoComplete="new-password"
                        placeholder="********"
                      />
                    </FormGroup>
                    {errors.general && (
                      <div className="note note--red">
                        {errors.general}
                      </div>
                    )}
                    <StyledLegal>
                      By clicking <strong>Sign up</strong>, you are agreeing to our <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
                    </StyledLegal>
                    <Button fill text="Sign up" rightIcon="caret-right" intent="primary" type="submit" disabled={isSubmitting} loading={isSubmitting} onSubmit={handleSubmit} />
                  </form>
                )}
              </Formik>
            </div>
          </AngledBorder>
          <div className="auth-alt">
            Have an account? <Link to="/login">Log In</Link>
          </div>
        </Box>
      </div>
    )
  }
}

export default withRouter(SignUp);