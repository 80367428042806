import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import MarketingHeader from './MarketingHeader';
import HomepageFeaturesSecondary from './HomepageFeaturesSecondary';
import HomepageWhy from './HomepageWhy';
import HomepageData from './HomepageData';
import MarketingSignUp from './MarketingSignUp';
import MarketingFooter from './MarketingFooter';

const StyledWhyPage = styled.div `

  .marketing-section__header {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      font-size: 1.728rem;
    }

    p {
      font-size: 1.2rem;
      font-weight: 300;
      color: ${props => props.theme.colorGrayDarker};
    }
  }

  @media screen and (max-width: 750px) {
    
  }
`

class Homepage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.mixpanel.track('Public Page Viewed', {page: 'Why'});
  }

  render() {
    if (this.props.authState === 'signedIn') {
      return <Redirect to='/trips' />
    }
    return (
      <>
        <MarketingHeader />
        <StyledWhyPage>
          <HomepageWhy  />
          <HomepageData />
          <HomepageFeaturesSecondary />
          <MarketingSignUp />
          <MarketingFooter />
        </StyledWhyPage>
      </>
    );
  }
}

export default Homepage;