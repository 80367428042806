import gql from 'graphql-tag';

export default gql`
  query listRecTopics($type: RecType) {
    listRecTopics(type: $type) {
      items {
        topicId
        recId
        order
        type
        typeUpdateTime
        name
        sentence
        summary
        descr
        pic
        updateTime
        createTime
        recCount
        localized
      }
    }
  }
`