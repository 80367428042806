import React, { Component } from 'react';

class Logo extends Component {
  render() {
    return (
      <svg version="1.1" viewBox="0 0 130 27">
        <title>Wanderium Logo</title>
        <g fill="none" fillRule="evenodd">
          <g fill="#333">
            <polygon id="W" fill="#333333" transform="translate(11.772000, 11.504000) rotate(-21.000000) translate(-11.772000, -11.504000) " points="17.448 20 14.136 20 11.784 7.232 11.736 7.232 9.384 20 6.072 20 2.832 3.008 5.76 3.008 7.944 16.208 8.04 16.208 10.32 3.008 13.344 3.008 15.696 16.208 15.744 16.208 17.928 3.008 20.712 3.008"></polygon>
            <path d="M30.952,25 L30.112,21.4 L25.648,21.4 L24.784,25 L21.904,25 L26.128,8.008 L29.8,8.008 L33.952,25 L30.952,25 Z M27.952,10.72 L27.856,10.72 L26.008,19.144 L29.752,19.144 L27.952,10.72 Z" id="A" fill="#333333" transform="translate(27.928000, 16.504000) rotate(18.000000) translate(-27.928000, -16.504000) "></path>
            <polygon id="N" fill="#333333" transform="translate(41.522197, 11.504000) rotate(-15.000000) translate(-41.522197, -11.504000) " points="43.6581972 20 38.8341972 8 38.7621972 8 38.8101972 20 36.2181972 20 36.2181972 3.008 39.5061972 3.008 44.2821972 15.08 44.3541972 15.08 44.3301972 3.008 46.8261972 3.008 46.8261972 20"></polygon>
            <path d="M62.992,16.528 C62.992,19.2160134 62.4280056,21.2999926 61.3,22.78 C60.1719944,24.2600074 58.512011,25 56.32,25 L52.072,25 L52.072,8.008 L56.344,8.008 C58.5200109,8.008 60.1719944,8.75599252 61.3,10.252 C62.4280056,11.7480075 62.992,13.8399866 62.992,16.528 Z M60.112,16.528 C60.112,15.519995 60.0200009,14.6400038 59.836,13.888 C59.6519991,13.1359962 59.3920017,12.5120025 59.056,12.016 C58.7199983,11.5199975 58.3200023,11.1480012 57.856,10.9 C57.3919977,10.6519988 56.8880027,10.528 56.344,10.528 L54.976,10.528 L54.976,22.48 L56.344,22.48 C56.8880027,22.48 57.3919977,22.3560012 57.856,22.108 C58.3200023,21.8599988 58.7199983,21.4880025 59.056,20.992 C59.3920017,20.4959975 59.6519991,19.8760037 59.836,19.132 C60.0200009,18.3879963 60.112,17.520005 60.112,16.528 Z" id="D" fill="#333333" transform="translate(57.532000, 16.504000) rotate(12.000000) translate(-57.532000, -16.504000) "></path>
            <polygon id="E" fill="#333333" transform="translate(69.804756, 12.504000) rotate(-9.000000) translate(-69.804756, -12.504000) " points="65.8807563 21 65.8807563 4.008 73.4887563 4.008 73.4887563 6.552 68.6407563 6.552 68.6407563 11.064 73.1287563 11.064 73.1287563 13.416 68.6407563 13.416 68.6407563 18.432 73.7287563 18.432 73.7287563 21"></polygon>
            <path d="M85.8925299,23 L83.2525299,15.752 L81.6445299,15.752 L81.6445299,23 L78.8605299,23 L78.8605299,6.008 L83.7085299,6.008 C84.4125334,6.008 85.060527,6.09999908 85.6525299,6.284 C86.2445329,6.46800092 86.7525278,6.759998 87.1765299,7.16 C87.600532,7.560002 87.9325287,8.06399696 88.1725299,8.672 C88.4125311,9.28000304 88.5325299,10.0079958 88.5325299,10.856 C88.5325299,11.5120033 88.4485308,12.0919975 88.2805299,12.596 C88.1125291,13.1000025 87.9005312,13.5279982 87.6445299,13.88 C87.3885286,14.2320018 87.1045315,14.5159989 86.7925299,14.732 C86.4805284,14.9480011 86.1725314,15.0959996 85.8685299,15.176 L88.9165299,23 L85.8925299,23 Z M85.7005299,11 C85.7005299,10.4879974 85.6285306,10.0640017 85.4845299,9.728 C85.3405292,9.39199832 85.1525311,9.12800096 84.9205299,8.936 C84.6885288,8.74399904 84.4285314,8.6080004 84.1405299,8.528 C83.8525285,8.4479996 83.5645314,8.408 83.2765299,8.408 L81.6445299,8.408 L81.6445299,13.64 L83.2765299,13.64 C83.9805334,13.64 84.5605276,13.4200022 85.0165299,12.98 C85.4725322,12.5399978 85.7005299,11.8800044 85.7005299,11 Z" id="R" fill="#333333" transform="translate(83.888530, 14.504000) rotate(6.000000) translate(-83.888530, -14.504000) "></path>
            <polygon id="I" fill="#333333" transform="translate(94.382673, 12.989174) rotate(-5.000000) translate(-94.382673, -12.989174) " points="92.9426728 21.4851744 92.9426728 4.49317442 95.8226728 4.49317442 95.8226728 21.4851744"></polygon>
            <path d="M110.98,16.7355345 C110.98,17.631539 110.884001,18.4715306 110.692,19.2555345 C110.499999,20.0395384 110.188002,20.7235316 109.756,21.3075345 C109.323998,21.8915374 108.768003,22.3515328 108.088,22.6875345 C107.407997,23.0235362 106.580005,23.1915345 105.604,23.1915345 C104.627995,23.1915345 103.804003,23.0235362 103.132,22.6875345 C102.459997,22.3515328 101.912002,21.8915374 101.488,21.3075345 C101.063998,20.7235316 100.760001,20.0395384 100.576,19.2555345 C100.391999,18.4715306 100.3,17.631539 100.3,16.7355345 L100.3,5.76753451 L103.18,5.76753451 L103.18,16.4715345 C103.18,16.9995372 103.216,17.5075321 103.288,17.9955345 C103.36,18.483537 103.487999,18.9115327 103.672,19.2795345 C103.856001,19.6475364 104.107998,19.9395334 104.428,20.1555345 C104.748002,20.3715356 105.155998,20.4795345 105.652,20.4795345 C106.148002,20.4795345 106.555998,20.3715356 106.876,20.1555345 C107.196002,19.9395334 107.447999,19.6475364 107.632,19.2795345 C107.816001,18.9115327 107.944,18.483537 108.016,17.9955345 C108.088,17.5075321 108.124,16.9995372 108.124,16.4715345 L108.124,5.76753451 L110.98,5.76753451 L110.98,16.7355345 Z" id="U" fill="#333333" transform="translate(105.640000, 14.479535) rotate(3.000000) translate(-105.640000, -14.479535) "></path>
            <polygon id="M" fill="#333333" points="126.664 22 126.712 8.944 126.64 8.944 123.232 22 120.976 22 117.64 8.944 117.568 8.944 117.616 22 115 22 115 5.008 118.864 5.008 122.128 17.44 122.224 17.44 125.416 5.008 129.472 5.008 129.472 22"></polygon>
          </g>
        </g>
      </svg>
    )
  }
}

export default Logo;