import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

const StyledHomepageFaq = styled.div`
  .faq__header {
    padding: 15px;
    background: #fff;
    border-bottom: 1px solid ${props => props.theme.colorGrayLight};

    h2 {
      margin-bottom: 0;
    }
  }

  .faq__content {
    max-width: 400px;
    min-width: 300px;
    width: 100%;

    .accordion:last-of-type {
      border-bottom: none;
    }
  }

  .accordion {
    position: relative;
    transition: all 120ms ease-in-out;
    background: #fff;
    padding: 15px;
    border-bottom: 1px solid ${props => props.theme.colorGrayLight};

    h4 {
      margin: 0;
      font-weight: 400;
    }

    p {
      overflow: hidden;
      position: relative;
      opacity: 0;
      max-height: 0;
      margin-bottom: 0;
      transform: translate(0, 15px);
      transition: all 120ms ease-in-out;
      color: ${props => props.theme.colorGrayDarker};
    }

    .caret {
      position: absolute;
      top: 17px;
      right: 15px;
      opacity: 0.5;
      transform: rotate(0deg);
      transition: all 120ms ease-in-out;

      .bp3-icon {
        position: relative;
        top: -2px;
      }
    }

    @media (pointer: fine) and (hover: hover) {
      &:hover {
        .caret { 
          opacity: 1;
        }

        h4 {
          color: ${props => props.theme.colorPrimary};
        }
      }
    }

    input[type=checkbox] {
      position: absolute;
      cursor: pointer;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      height: 50px;
      z-index: 1;
      opacity: 0;

      &:checked {
        &~p {
          transform: translate(0, 0);
          opacity: 1;
          max-height: 500px;
          margin-top: 10px;
        }

        &~.caret {
          transform: rotate(180deg);
        }
      }
    }

  }
`

class HomepageFaq extends Component {

  render() {
    return (
      <StyledHomepageFaq>
        <div className="faq__header">
          <h2>Common questions</h2>
        </div>
        <div className="faq__content">
          <div className="accordion">
            <input type="checkbox"/>
            <div className="caret"><Icon icon="caret-down" iconSize={16} /></div>
            <h4>How much does it cost?</h4>
            <p>Free users get 1 trip a year included and can get more trips for $15 each. Premium users get unlimited trips plus more. <Link to="/pricing">See pricing for more details.</Link></p>
          </div>
          <div className="accordion">
            <input type="checkbox"/>
            <div className="caret"><Icon icon="caret-down" iconSize={16} /></div>
            <h4>Who can use Wanderium?</h4>
            <p>Currently, Wanderium is only available to US and Canadian citizens. We will expand to other countries in 2021.</p>
          </div>
          <div className="accordion">
            <input type="checkbox"/>
            <div className="caret"><Icon icon="caret-down" iconSize={16} /></div>
            <h4>Do you have an iOS or Android app?</h4>
            <p>Not yet. While Wanderium works great on your smartphone&#39;s browser, we plan to have native iOS and Android apps towards early 2020.</p>
          </div>
        </div>
      </StyledHomepageFaq>
    );
  }
}

export default HomepageFaq;