import React, { Component } from "react";
import { Route } from "react-router-dom";

// Component for passing all props of a route into its rendered component
class RouteWrapper extends Component {
  render() {
    const { Component, path, exact, ...rest } = this.props;
    return (
      <Route
          path={path}
          exact={exact}
          render={props => <Component {...rest} {...props} />}
      />
    );
  }
}

export default RouteWrapper;