import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../shared/Logo';
import { Link } from 'react-router-dom';

const StyledMarketingFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 10px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(255,255,255,0.6);
  background: ${props => props.theme.colorBlack};
  box-shadow: 0 50vh 0 50vh #fff;

  .divider {
    opacity: 0.4;
  }

  .marketing-footer__logo {
    width: 120px;

    svg {
      filter: invert(100%);
    }
  }

  .marketing-footer__links {
    margin: 10px 0 0;
    text-align: center;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 2em;

    > li {
      display: inline-block;

      a {
        padding: 5px 10px;
      }
    }
  }

  a {
    color: rgba(255,255,255,0.9);
  }

  .marketing-footer__copyright {
    text-align: center;

    > span {
      margin-left: 5px;
    }

    @media screen and (max-width: 400px) {
      > span {
        display: block;
        margin-top: 10px;
      }
    }
  }
`

class MarketingFooter extends Component {

  render() {
    return (
      <StyledMarketingFooter>
        <div className="marketing-footer__logo">
          <Link to="/"><Logo /></Link>
        </div>
        <div className="marketing-footer__links">
          <ul>
            <li><Link to="/about">About</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><a href="mailto:yourfriends@wanderium.com" target="_blank" rel="noopener noreferrer">Support</a></li>
            <li><a href="mailto:partner@wanderium.com" target="_blank" rel="noopener noreferrer">Partner</a></li>
            <li><a href="https://www.twitter.com/wanderium" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://www.instagram.com/wanderiumapp" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </div>
        <div className="divider divider--m"></div>
        <div className="marketing-footer__copyright">
          &copy; 2020 Wanderium. All Rights Reserved.<span><Link to="/terms">Terms</Link> &middot; <Link to="/privacy">Privacy</Link></span>
        </div>
      </StyledMarketingFooter>
    );
  }
}

export default MarketingFooter;