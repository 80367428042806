import React, { Component } from 'react';
import styled from 'styled-components';

const StyledBlock = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.thin ? '0' : '80px 15px'};
  border-top: ${props => !props.noBorder && '1px solid'};
  border-color: ${props => props.theme.colorGrayLight}

  .marketing-block__header {
    width: 200px;

    h2 {
      font-weight: 500;
      font-size: ${props => props.wideHeader ? '1.3rem' : '1.5rem'};
      line-height: ${props => props.wideHeader ? '1.4em' : '1.25em'};
      margin-top: -3px;
    }

    .divider {
      display: none;
    }
  }

  .marketing-block__content {
    width: 100%;
    max-width: 500px;
    margin-left: 30px;
    font-weight: 300;
    font-size: 1.1rem;

    h3~p {
      margin-bottom: 15px;
    }

    h3 {
      font-weight: 500;
      line-height: 1.5em;
      margin-top: 25px;

      &:first-child {
        margin-top: 0;
      }
    }

    p a {
      //border-bottom: 1px solid;
      text-decoration: underline;
      text-decoration-skip: object;

      &:hover {
        //text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;

    .marketing-block__header {
      max-width: 500px;
      width: 100%;

      h2 {
        margin-bottom: 1rem;
      }

      .divider {
        display: block;
      }
    }

    .marketing-block__content {
      margin-left: 0;
    }
  }
`

class MarketingBlock extends Component {
  render() {
    return (
      <StyledBlock className={this.props.className} noBorder={this.props.noBorder} thin={this.props.thin} wideHeader={this.props.wideHeader}>
        <div className="marketing-block__header">
          <h2>{this.props.header}</h2>
          <div className="divider divider--m divider--left divider--thick"></div>
        </div>
        <div className="marketing-block__content">
          {this.props.children}
        </div>
      </StyledBlock>
    );
  }
}

export default MarketingBlock;