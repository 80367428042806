import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import LoadingPage from '../shared/LoadingPage';
import getUser from '../../queries/getUser';
import { Redirect } from 'react-router-dom';
import Auth from '@aws-amplify/auth';
import { Button } from '@blueprintjs/core';
import Gate from '../onboarding/Gate';

class LaunchGate extends Component {
  componentDidMount() {
    this.props.mixpanel.track('Onboard Not Yet Viewed');
  }

  handleSignOut = () => {
    Auth.signOut();
  }

  render() {
    if (this.props.data.loading) {
      return <LoadingPage />
    }

    const { onboarded } = this.props.data.getUser;

    if (onboarded === 'COMPLETED_V0') {
      return <Redirect to='/trips' />
    } else if (onboarded === 'INCOMPLETE') {
      return <Redirect to='/welcome' />
    }

    return (
      <Gate>
        <h2>Shucks, we aren&#39;t ready for you yet</h2>
        <p>Wanderium is currently limited to US and Canadian citizens. You&#39;ll be first to know when we launch in your country.</p>
        <p>Questions? <a href="mailto:yourfriends@wanderium.com">yourfriends@wanderium.com</a></p>
        <div className="gate__actions">
          <Button text="Sign out" intent="PRIMARY" large onClick={this.handleSignOut} />
        </div>
      </Gate>
    )
  }
}

export default graphql(getUser, {
  options: {
    fetchPolicy: 'network-only'
  }
})(LaunchGate);