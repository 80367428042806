import AWSAppSyncClient from 'aws-appsync';

export const makeCommaSeparatedString = (arr, useOxfordComma, max) => {
  let listStart;
  let listEnd;
  if (typeof max !== undefined && arr.length > max + 1) {
    // Will limit the number of entities shown and then append "& 5 more" or whatever more there are
    listStart = arr.slice(0, max).join(', ');
    listEnd = `${arr.length - max} more`;
  } else {
    listStart = arr.slice(0, -1).join(', ');
    listEnd = arr.slice(-1);
  }
  const conjunction = arr.length <= 1 ? '' :
      useOxfordComma && arr.length > 2 ? ', & ' : ' & ';
  return [listStart, listEnd].join(conjunction);
}

export const clientUnauthenticated = new AWSAppSyncClient({
  url: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_APPSYNC_URL_PROD : process.env.REACT_APP_APPSYNC_URL_DEV,
  region: process.env.REACT_APP_AWS_REGION,
  auth: {
    type: "API_KEY",
    apiKey: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_APPSYNC_KEY_PROD : process.env.REACT_APP_APPSYNC_KEY_DEV
  },
  disableOffline: true
})

export const randomNoRepeats = array => {
  var copy = array.slice(0);
  return function() {
    if (copy.length < 1) { copy = array.slice(0); }
    var index = Math.floor(Math.random() * copy.length);
    var item = copy[index];
    copy.splice(index, 1);
    return item;
  };
}