import React from 'react';
import { Spinner } from "@blueprintjs/core";
import styled from 'styled-components';

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 120px 10px;
  width: 100%;

  &.--centered-vert {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
`

function Loader(props) {
  return (
    <StyledLoader className={props.vertCentered ? '--centered-vert' : ''}>
      <Spinner />
    </StyledLoader>
  )
}

export default Loader;