import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from '../shared/Logo';
import { Link, withRouter } from 'react-router-dom';
import LinkButton from '../shared/LinkButton';
import Auth from '@aws-amplify/auth';

const StyledMarketingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;

  .marketing-header__logo {
    width: 140px;
    margin-right: 15px;
    margin-top: 2px;
    cursor: pointer;
    position: relative;
    z-index: 101;
  }

  .marketing-header__links {
    margin-right: -6px;

    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;
      align-items: center;
    }

    li > a {
      padding: 10px;
      margin: 0 6px;
      font-weight: 500;
      color: #000;
      position: relative;

      &.marketing-header__link {
        @media (pointer: fine) and (hover: hover) {
          &:hover {
            text-decoration: none;

            &:after {
              content: '';
              position: absolute;
              width: calc(100% - 20px);
              height: 1px;
              left: 10px;
              bottom: 6px;
              background-color: #000;
            }
          }
        }
      }
    }

    .bp3-button.bp3-intent-secondary {
      box-shadow: 0 0 0 1px rgba(0,0,0,0.6);
      color: rgba(0,0,0,0.9);

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          box-shadow: 0 0 0 1px rgba(0,0,0,0.8);
        }
      }
    }

    @media screen and (max-width: 500px) {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: fixed;
      z-index: 100;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      // Initial position for transition
      transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
      opacity: 0;
      transform: translateY(-50px);
      visibility: hidden;

      &.active {
        opacity: 1;
        transform: translateY(0);
        visibility: visible;
      }

      ul {
        flex-direction: column;
        justify-content: center;

        li {
          margin-bottom: 25px;
          font-size: 1.2rem;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .marketing-header__toggle {
    cursor: pointer;
    float: right;
    display: none; // By default hidden;
    position: relative;
    height: 30px;;
    width: 40px;
    position: relative;
    right: -5px;
    border-radius: 0;
    z-index: 101;

    @media screen and (max-width: 500px) {
      display: block;
    }

    span {
      background-color: #333;
      display: block;
      height: 1px;
      left: 50%;
      margin-left: -7px;
      position: absolute;
      top: 50%;
      transition: none 86ms ease-out;
      transition-property: background, left, opacity, transform;
      width: 15px;

      &:nth-child(1) {
        margin-top: -6px;
      }
      &:nth-child(2) {
        margin-top: -1px;
      }
      &:nth-child(3) {
        margin-top: 4px;
      }
    }

    // If the mobile nav menu is open
    &.active {
      span {
        &:nth-child(1) {
          margin-left: -5px;
          transform: rotate(45deg);
          transform-origin: left top;
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          margin-left: -5px;
          transform: rotate(-45deg);
          transform-origin: left bottom;
        }
      }
    }
  }
`

class MarketingHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      authState: 'LOGGED_OUT'
    };
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser().then(resp => {
      this.setState({
        authState: 'LOGGED_IN'
      });
    }).catch(err => {
      // Don't do anything with error
    })
  }

  toggleMobile = () => {
    this.setState(state => ({
      mobileOpen: !state.mobileOpen
    }))
  }

  render() {
    return (
      <StyledMarketingHeader>
        <div className="marketing-header__logo" onClick={() => {this.props.history.push('/')}}>
          <Logo />
        </div>
        <div className={`marketing-header__links ${this.state.mobileOpen ? 'active' : ''}`}>
          <ul>
            {this.state.authState === 'LOGGED_OUT' && (
              <>
                <li><Link className="marketing-header__link" to="/why">Why Wanderium</Link></li>
                <li><Link className="marketing-header__link" to="/recs">Gear &amp; Apps</Link></li>
                <li><Link className="marketing-header__link" to="/about">About</Link></li>
                <li><Link className="marketing-header__link" to="/pricing">Pricing</Link></li>
                <li><Link className="marketing-header__link" to="/login">Log in</Link></li>
                <li><LinkButton intent="secondary" rightIcon="caret-right" large={this.state.mobileOpen} to="/signup" text="Sign up" /></li>
              </>
            )}
            {this.state.authState === 'LOGGED_IN' && (
              <>
                <li><Link className="marketing-header__link" to="/trips">Trips</Link></li>
                <li><Link className="marketing-header__link" to="/trips/new">Add Trip</Link></li>
                <li><Link className="marketing-header__link" to="/recs">Gear &amp; Apps</Link></li>
              </>
            )}
          </ul>
        </div>
        <div className={`marketing-header__toggle ${this.state.mobileOpen ? 'active' : ''}`} onClick={this.toggleMobile}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </StyledMarketingHeader>
    );
  }
}

export default withRouter(MarketingHeader);