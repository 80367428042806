import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import listRecTopics from '../../queries/listRecTopics';
import Loader from '../shared/Loader';
import LinkButton from '../shared/LinkButton';
import styled from 'styled-components';
import RecTopicCard from '../recs/RecTopicCard';
import { clientUnauthenticated } from '../../helpers/generalHelpers';
import { randomNoRepeats } from '../../helpers/generalHelpers';

const StyledRecTopicsSection = styled.div`
  background-color: ${props => props.theme.colorPurpleLighter};

  .trips-recs__wrapper-inner {
    max-width: 750px;
    padding: 40px 15px;
    margin: 0 auto;
  }

  .trips-recs__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h4 {
      font-weight: 400;
      font-size: 1.2rem;
      margin: 0;
    }
  }

  .trips-recs__content {
    display: flex;

    .rec-topic__card {
      margin-right: 1rem;
      flex: 1 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .trips-recs__content {
      flex-direction: column;

      .rec-topic__card {
        margin-right: 0;
      }
    }
  }
`

class RecTopicsSection extends Component {
  state = {
    recsReady: false
  }

  componentDidMount() {
    if (!this.props.data.loading) {
      // Recs cached
      this.chooseTopics();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.loading && !this.props.data.loading) {
      // Recs loaded
      this.chooseTopics();
    }
  }

  chooseTopics = () => {
    console.log('chooseTopics listRecTopics: ', JSON.stringify(this.props.data.listRecTopics, null, 5));
    const recTopics = this.props.data.listRecTopics.items;
    
    const gearChooser = randomNoRepeats(recTopics.filter(topic => {
      return topic.type === 'GEAR'
    }));
    const appsChooser = randomNoRepeats(recTopics.filter(topic => {
      return topic.type === 'APP'
    }));

    this.chosenTopics = [];

    // Ensure there's at least 2 gear guides shown each time
    this.chosenTopics.push(gearChooser());
    this.chosenTopics.push(appsChooser());
    this.chosenTopics.push(gearChooser());
    
    this.setState({
      recsReady: true
    })
  }

  render() {
    return (
      <StyledRecTopicsSection className="rec-topics-section">
        <div className="trips-recs__wrapper-outer">
          <div className="trips-recs__wrapper-inner">
            <div className="trips-recs__header">
              <h4>{this.props.title}</h4>
              <LinkButton to="/recs" intent="secondary">See All Guides</LinkButton>
            </div>
            <div className="trips-recs__content">
              {!this.state.recsReady && <Loader/>}
              {this.state.recsReady && this.chosenTopics.map(topic => (
                <RecTopicCard key={topic.topicId} rec={topic} handleListOpen={this.handleListOpen} />
              ))}
            </div>
          </div>
        </div>
      </StyledRecTopicsSection>
    )
  }
}

export default graphql(listRecTopics, {
  options: {
    client: clientUnauthenticated
  }
})(RecTopicsSection);