import { MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer } from "@blueprintjs/select";
import * as React from "react";
import countries from "../../data/countries.json";
import languages from '../../data/languages.json';

// Countries
export interface ICountry {
    /** Common name of the country */
    name: string;
    /** Emoji flag of country */
    flag: string;
    /** UN region of the code */
    region: string;
    /** ISO country code. */
    code: string;
}

export const COUNTRY_LIST: ICountry[] = countries.map((m, index) => ({ ...m, rank: index + 1 }));

export const renderCountry: ItemRenderer<ICountry> = (country, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${country.flag} ${country.name}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            label={country.region.split('#')[1].toString()}
            key={country.code}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const filterCountry: ItemPredicate<ICountry> = (query, country) => {
    return `${country.name.toLowerCase()} ${country.region}`.indexOf(query.toLowerCase()) >= 0;
};

// Languages
export interface ILanguage {
    // Common name of the language
    name: string;
    // Native name of the language
    local: string;
    // ISO 3 letter code
    code: string;
}

export const LANGUAGE_LIST: ILanguage[] = languages.map((m, index) => ({ ...m, rank: index + 1 }));

// Not currently used because of needed access to Formik's values
export const renderLanguage: ItemRenderer<ILanguage> = (language, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${language.name}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={language.code}
            onClick={handleClick}
            text={highlightText(text, query)}
        />
    );
};

export const filterLanguage: ItemPredicate<ILanguage> = (query, language) => {
    return `${language.name.toLowerCase()}`.indexOf(query.toLowerCase()) >= 0;
};


// Select helpers used in all Select components
function highlightText(text: string, query: string) {
    let lastIndex = 0;
    const words = query
        .split(/\s+/)
        .filter(word => word.length > 0)
        .map(escapeRegExpChars);
    if (words.length === 0) {
        return [text];
    }
    const regexp = new RegExp(words.join("|"), "gi");
    const tokens: React.ReactNode[] = [];
    while (true) {
        const match = regexp.exec(text);
        if (!match) {
            break;
        }
        const length = match[0].length;
        const before = text.slice(lastIndex, regexp.lastIndex - length);
        if (before.length > 0) {
            tokens.push(before);
        }
        lastIndex = regexp.lastIndex;
        tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
    }
    const rest = text.slice(lastIndex);
    if (rest.length > 0) {
        tokens.push(rest);
    }
    return tokens;
}

function escapeRegExpChars(text: string) {
    // eslint-disable-next-line no-useless-escape
    return text.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}