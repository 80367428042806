import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from "react-helmet";

const StyledNoMatch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 15px;

  h1 {
    font-weight: 400;
    margin-bottom: 20px;
  }
`

class NoMatch extends Component {
  componentDidMount() {
    this.props.mixpanel.track('404 Viewed');
  }
  
  render() {
    return (
      <>
        <Helmet>
          <meta name="prerender-status-code" content="404"/>
          <title>404: Page Not Found</title>
        </Helmet>
        <StyledNoMatch>
          <h1>Woops, this page doesn&#39;t exist</h1>
          <p>You can <a href="https://www.wanderium.com">return to our homepage</a> or <a href="mailto:yourfriends@wanderium.com">get help</a>.</p>
        </StyledNoMatch>
      </>
    );
  }
}

export default NoMatch;