import React, { Component } from 'react';
import { FormGroup, Button } from "@blueprintjs/core";
import { Box } from 'reflexbox';
import { Formik } from 'formik';
import * as yup from 'yup';
import Auth from '@aws-amplify/auth';
import { Redirect, withRouter, Link } from 'react-router-dom';
import Logo from '../shared/Logo';
import AngledBorder from '../shared/AngledBorder';
import { Helmet } from "react-helmet";

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email("Invalid email"),
  password: yup.string().required('Password is required'),
})

class SignIn extends Component {
  componentDidMount() {
    this.props.mixpanel.track('Log In Viewed');
  }

  handleSignIn = (values, actions) => {
    Auth.signIn(values.email.toLowerCase(), values.password)
      .then(resp => {
        //console.log('successful signin: ', resp);
        this.props.mixpanel.track('Logged In');
        this.props.mixpanel.identify(resp.username);
        //actions.setSubmitting(false);
        
        if (resp.attributes.email_verified === false) {
          //console.log('Email not verified yet');
          Auth.verifyCurrentUserAttribute('email').then(() => {
            this.props.onStateChange('confirmEmail', values.email.toLowerCase());  // Change user Auth state
            this.props.handleConfirmStore(values.email.toLowerCase(), values.password); // Temp save of credentials
            this.props.history.push('/confirm');  // Go to confirm page
          }).catch(() => {
            //console.log(err);
          });
        } else {
          this.props.onStateChange('signedIn');
          this.props.history.push('/trips');
        }
      })
      .catch(err => {
        if (err.code === "UserNotConfirmedException") {
          // If user isn't confirmed yet, resend code and redirect to confirm page
          Auth.resendSignUp(values.email.toLowerCase()).then(() => {
            //console.log('code resent successfully');
            this.props.onStateChange('confirmSignUp', values.email.toLowerCase());  // Change user Auth state
            this.props.handleConfirmStore(values.email.toLowerCase(), values.password); // Temp save of credentials
            this.props.history.push('/confirm');  // Go to confirm page
          }).catch(() => {
            //console.log(err);
          });
        } else {
          //console.log('signin error: ', err);
          actions.setSubmitting(false);
          actions.setErrors({ general: err.message })
        }
      });
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/trips' } };

    if (this.props.authState === 'signedIn' && from) {
      return <Redirect to={from} />
    }

    return (
      <div className="auth-wrapper">
        <Helmet>
          <title>Log In</title>
        </Helmet>
        <Box w={270}>
          <div className="auth-logo">
            <Link to="/"><Logo /></Link>
          </div>
          {((this.props.location.state && this.props.location.state.from) || !this.props.location.state) && (
            <div className="auth-header">
              <h3>Welcome back</h3>
            </div>
          )}
          {this.props.location.state && this.props.location.state.msg && (<div className="auth-msg auth-msg--error">{this.props.location.state.msg}</div>)}
          {this.props.location.state && this.props.location.state.resetPass && (<div className="auth-msg auth-msg--success">New password set, please log in</div>)}
          {this.props.location.state && this.props.location.state.loggedOut && (<div className="auth-msg auth-msg--success">Logged out successfully</div>)}
          <AngledBorder>
            <div className="auth-form">
              <Formik
                initialValues={{
                  email: '',
                  password: ''
                }}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={this.handleSignIn}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormGroup
                      label="Email"
                      labelFor="email"
                      helperText={errors.email && touched.email && errors.email}
                      intent={errors.email && touched.email && 'DANGER'}
                    >
                      <input
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        name="email"
                        id="email"
                        className={`bp3-input ${errors.email && touched.email && 'bp3-intent-danger'}`}
                        autoComplete="new-password"
                        placeholder="beep@boop.com"
                        autoFocus
                        tabIndex="1"
                      />
                    </FormGroup>
                    <FormGroup
                      label="Password"
                      labelFor="password"
                      helperText={errors.password && touched.password && errors.password}
                      intent={errors.password && touched.password && 'DANGER'}
                    >
                      <Link className="form-label-link" to="/forgot">Forgot password?</Link>
                      <input
                        type="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        name="password"
                        id="password"
                        className={`bp3-input ${errors.password && touched.password && 'bp3-intent-danger'}`}
                        autoComplete="new-password"
                        placeholder="********"
                        tabIndex="2"
                      />
                    </FormGroup>
                    {errors.general && <div style={{color: "#E03131", margin: "-5px 0 15px"}}>{errors.general}</div>}
                    <Button tabIndex="3" fill text="Log in" rightIcon="caret-right" intent="primary" type="submit" disabled={isSubmitting} loading={isSubmitting} onSubmit={handleSubmit} />
                  </form>
                )}
              </Formik>
            </div>
          </AngledBorder>
          <div className="auth-alt">
            Need an account? <Link to="/signup">Sign up</Link>
          </div>
        </Box>
      </div>
    )
  }
}

export default withRouter(SignIn);