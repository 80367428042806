import React, { Component } from 'react';
import MarketingSignUp from './MarketingSignUp';
import MarketingFooter from './MarketingFooter';
import MarketingHeader from './MarketingHeader';
import MarketingBlock from './MarketingBlock';
import styled from 'styled-components';
import { Helmet } from "react-helmet";

const StyledAbout = styled.div`
  .about-cover__wrapper {
    background: ${props => props.theme.colorBeigeLight};
    padding: 100px 15px;
    position: relative;
  }

  .about-cover {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }

  .about-cover__content {
    position: relative;
    z-index: 1;

    h1 {
      font-weight: 400;
      font-size: 3rem;
      line-height: 1.2em;
      margin-bottom: 1rem;
      margin-top: 0;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.7em;
      font-weight: 300;
    }

    .divider {
      margin: 2rem 0;
    }

    @media screen and (max-width: 600px) {
      h1 {
        font-size: 2.4rem;
      }

      p {
        font-size: 1.2rem;
      }
    }
  }


  .about-cover__pic {
    position: absolute;
    top: 0;
    right: 0;
    left: 40%;
    bottom: 0;
    background-image: url(${process.env.PUBLIC_URL + '/bg-about.jpg'});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    z-index: 0;

    &:before {
      background: linear-gradient(110deg, ${props => props.theme.colorBeigeLight} 31%, transparent 100%);
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    @media screen and (max-width: 900px) {
      left: 30%;

      &:before {
        background: linear-gradient(101deg, ${props => props.theme.colorBeigeLight} 25%, transparent 100%);
      }
    }
    @media screen and (max-width: 700px) {
      left: 20%;
      opacity: 0.9;

      &:before {
        background: linear-gradient(95deg, ${props => props.theme.colorBeigeLight} 20%, transparent 100%);
      }
    }
    @media screen and (max-width: 550px) {
      left: 10%;
      opacity: 0.8;
      
      &:before {
        background: linear-gradient(90deg, ${props => props.theme.colorBeigeLight} 15%, transparent 100%);
      }
    }
  }


  .about-values {
    background: #fff;
  }
`

class About extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.mixpanel.track('Public Page Viewed', {page: 'About'});
  }
  
  render() {
    return (
      <StyledAbout>
        <Helmet>
          <title>About</title>
          <meta property="og:title" content="About | Wanderium" />
        </Helmet>
        <MarketingHeader />
        <div className="about-cover__wrapper">
          <div className="about-cover">
            <div className="about-cover__content">
              <h1>Unlock the world.<br/>Unlock your mind.</h1>
              <div className="divider divider--m"></div>
              <p>We believe in the power of traveling. How it shifts our perspective and questions our assumptions. It&#39;s one of the best (and most fun) ways to make the world a better place.</p>
              <p>But traveling today means an overwhelming amount of information to wade through and figure out, all spread across dozens of places. We’re here to fix that.</p>
              <p>Our mission is make traveling easier than ever before, so you can spend all that time and energy on more important things.</p>
            </div>
            <div className="about-cover__pic"></div>
          </div>
        </div>
        <MarketingBlock className="about-values" noBorder header="Our values">
          <h3 className="with-shape"><div className="shape triangle bc-blue-dark"></div>Quality</h3>
          <p>Not the most nor the newest, just the best. 4 great options instead of 20 mediocre ones. Think of us like that friend with great taste whose recommendations you trust wholeheartedly.</p>
          <h3 className="with-shape"><div className="shape parallelogram bg-yellow-dark"></div>Brevity</h3>
          <p>More substance in less words. We think most people would rather just be told the answer than dig through 10 paragraphs or 5 pages of reviews for it. Time is valuable.</p>
          <h3 className="with-shape"><div className="shape circle bg-green-dark"></div>Responsibility</h3>
          <p>For all its benefits, travel also has its problems: <a href="https://www.nytimes.com/2018/08/29/technology/technology-overtourism-europe.html">overtourism</a>, <a href="https://www.nytimes.com/2017/07/27/climate/airplane-pollution-global-warming.html">high carbon emissions</a>, <a href="https://skift.com/2019/04/29/hotels-have-a-problem-with-plastics-a-skift-deep-dive/">plastic overuse</a>, and more. We&#39;ll bring focus to ways you can travel more responsibly.</p>
        </MarketingBlock>
        <MarketingBlock noBorder header="The team">
          <p>Wanderium is built by a small but dedicated team, including its founder <a href="https://www.twitter.com/jobosapien" target="_blank" rel="noopener noreferrer">@jobosapien</a>. He thought there should be a better way to travel after living in 25+ countries over 2 years, so he decided to build it himself. Feel free to slide into his DMs.</p>
          <p>Love what we’re doing? Interested in joining the team? Introduce yourself at <a href="mailto:joinus@wanderium.com">joinus@wanderium.com</a>.</p>
        </MarketingBlock>
        <MarketingSignUp />
        <MarketingFooter />
      </StyledAbout>
    );
  }
}

export default About;