import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import TextRotater from './TextRotater';
import LinkButton from '../shared/LinkButton';
import { Button } from '@blueprintjs/core';
import MarketingHeader from './MarketingHeader';
import HomepageFeatures from './HomepageFeatures';
import HomepageHow from './HomepageHow';
import HomepageQuotes from './HomepageQuotes';
import HomepageFaq from './HomepageFaq';
import MarketingSignUp from './MarketingSignUp';
import MarketingFooter from './MarketingFooter';
import MarketingInactiveBanner from './MarketingInactiveBanner';
import AngledBorder from '../shared/AngledBorder';
import ExploreSection from '../shared/ExploreSection';
import RecTopicsSection from '../recs/RecTopicsSection';

const content = [
  {
    text: 'Will I need a visa?'
  },
  {
    text: 'What’s the expected weather?'
  },
  {
    text: 'Will I need a plug adapter?'
  },
  {
    text: 'Will I need vaccinations?'
  },
  {
    text: 'What should I pack?'
  },
  {
    text: 'Is there a dress code?'
  },
  {
    text: 'Is Uber or Lyft there?'
  },
  {
    text: 'Does my passport expire too early?'
  },
  {
    text: 'Is it safe?'
  },
  {
    text: 'Do I tip there?'
  },
  {
    text: 'Will I need a permit to drive there?'
  },
];

const StyledHomepage = styled.div `
  .marketing-cover__wrapper {
    padding: 110px 15px;
    position: relative;
  }

  .marketing-cover {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h1 {
      margin-bottom: 20px;
      margin-top: 0;
      font-weight: 500;
      color: #000;
    }
  }

  .marketing-cover__content {
    width: 70%;
    padding-right: 3%;
    text-align: left;
    color: #000;
  }

  .marketing-cover__pic {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    left: 40%;
    bottom: 0;
    background-image: url(${process.env.PUBLIC_URL + '/bg-hp.jpg'});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    &:before {
      background: linear-gradient(103deg, ${props => props.theme.colorTealLighter} 15%, transparent 100%);
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    @media screen and (max-width: 900px) {
      left: 30%;

      &:before {
        background: linear-gradient(98deg, ${props => props.theme.colorTealLighter} 15%, transparent 100%);
      }
    }
    @media screen and (max-width: 700px) {
      left: 20%;
      opacity: 0.9;

      &:before {
        background: linear-gradient(94deg, ${props => props.theme.colorTealLighter} 12%, transparent 100%);        
      }
    }
    @media screen and (max-width: 550px) {
      left: 10%;
      opacity: 0.8;

      &:before {
        background: linear-gradient(90deg, ${props => props.theme.colorTealLighter} 9%, transparent 100%);
      }
    }
  }

  .marketing-cover__rotate {
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .marketing-cover__descr {
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .marketing-cover__supported {
    font-size: 12px;
    margin-top: 10px;
    color: ${props => props.theme.colorGrayDarker};
  }

  .marketing-section__header {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      font-size: 1.728rem;
    }

    p {
      font-size: 1.2rem;
      font-weight: 300;
      color: ${props => props.theme.colorGrayDarker};
    }
  }

  .marketing-faq-partner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 80px 25px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .marketing-parter__wrapper {
    margin-left: 4rem;

    @media screen and (max-width: 700px) {
      margin-left: 0;
      margin-top: 40px;
    }
  }

  .marketing-partner {
    min-width: 300px;
    max-width: 400px;
    width: 100%;
    padding: 30px;
    background: #fff;

    p {
      color: ${props => props.theme.colorGrayDarker};
      margin-bottom: 15px;
    }
  }

  .rec-topics-section {
    padding: 60px 15px;
  }

  @media screen and (max-width: 750px) {
    .marketing-cover__content {
      width: auto;
      max-width: 700px;
      margin: 0 auto;
      padding-right: 0;

      h1 {
        font-size: 1.768rem;
      }

      .marketing-cover__rotate, .marketing-cover__descr {
        font-size: 1.2rem;
      }
    }
  }
`

class Homepage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.mixpanel.track('Public Page Viewed', {page: 'Homepage'});
  }

  render() {
    if (this.props.authState === 'signedIn') {
      return <Redirect to='/trips' />
    }
    return (
      <>
        <MarketingInactiveBanner />
        <MarketingHeader />
        <StyledHomepage>
          <div className="marketing-cover__wrapper">
            <div className="marketing-cover">
              <div className="marketing-cover__content">
                <h1>Prepare for your trip in<br/> minutes, not hours.</h1>
                <div className="divider divider--m divider--left"></div>
                <div className="marketing-cover__rotate">
                  <TextRotater
                    content={content}
                    time={2350}
                    startDelay={0}
                    transitionTime={120}
                  />
                </div>
                <p className="marketing-cover__descr">
                  Let us do the work for you.
                </p>
                <LinkButton large intent="primary" rightIcon="caret-right" to='/signup'>Sign up for free</LinkButton>
                <div className="marketing-cover__supported">Currently available for US and Canadian citizens.</div>
              </div>
              <div className="marketing-cover__pic"></div>
            </div>
          </div>
          <ExploreSection tall />
          <HomepageFeatures />
          <RecTopicsSection title="Explore The Best Travel Gear & Apps"/>
          <HomepageHow />
          <HomepageQuotes />
          <div className="marketing-faq-partner">
            <AngledBorder>
              <HomepageFaq />
            </AngledBorder>
            <div className="marketing-parter__wrapper">
              <AngledBorder>
                <div className="marketing-partner">
                  <h2>Help your city&#39;s visitors</h2>
                  <div className="divider divider--s divider--left"></div>
                  <p>We partner with governments and tourism boards around the world to ensure their visitors have the information they need to arrive prepared.</p>
                  <a className="link-button" href="mailto:partner@wanderium.com"><Button rightIcon="caret-right" intent="secondary" text="Partner with us"/></a>
                </div>
              </AngledBorder>
            </div>
          </div>
          <MarketingSignUp />
          <MarketingFooter />
        </StyledHomepage>
      </>
    );
  }
}

export default Homepage;