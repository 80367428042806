import React, { Component } from 'react';
import MarketingSignUp from './MarketingSignUp';
import MarketingFooter from './MarketingFooter';
import MarketingHeader from './MarketingHeader';
import MarketingBlock from './MarketingBlock';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import LinkButton from '../shared/LinkButton';
import { Helmet } from "react-helmet";

const StyledPricing = styled.div`
  .pricing-cover__wrapper {
    padding: 100px 15px;
    background: ${props => props.theme.colorGreenLight};
    position: relative;
  }

  .pricing-cover__content {
    text-align: center;
    position: relative;
    z-index: 1;

    h1 {
      font-weight: 500;
    }

    h2 {
      font-weight: 300;
      margin-top: 20px;
    }
  }

  .pricing-plans__wrapper {
    padding: 50px 15px 80px;
    background: #fff;

    @media screen and (max-width: 700px) {
      background: none;
    }
  }

  .marketing-pricing__plans {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }

  .marketing-pricing__plan-row {
    width: 100%;
    display: grid;
    grid-template-columns: 200px 24px repeat(2,minmax(auto,210px));
    grid-gap: 30px;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid ${props => props.theme.colorGrayLight};

    @media screen and (max-width: 900px) {
      grid-template-columns: 160px 0 repeat(2,1fr);
    }
  }

  .marketing-pricing__features {
    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  .plan-row__feature {
    font-weight: 500;

    p {
      font-weight: 300;
      color: ${props => props.theme.colorGrayDarker};
      margin-top: 4px;
      margin-bottom: 0;
    }
  }

  .plan-row__feature-value {
    width: 210px;
    text-align: center;

    p {
      font-weight: 300;
      color: ${props => props.theme.colorGrayDarker};
      margin-top: 3px;
      margin-bottom: 0;
    }
  }

  .marketing-pricing__plan-header {
    padding-bottom: 30px;

    @media screen and (max-width: 700px) {
      border-bottom: none;
      padding-bottom: 0;

      > div:not(.plan-row__plan-card) {
        display: none;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .plan-row__plan-card {
    width: 210px;
    text-align: center;

    h3 {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 1.2rem;
    }

    .plan-card__descr {
      font-weight: 300;
      margin-bottom: 5px;
      color: ${props => props.theme.colorGrayDarker};
    }

    .plan-card__annual {
      font-weight: 500;
      margin-bottom: 5px;
    }

    .plan-card__monthly {
      font-weight: 300;
      color: ${props => props.theme.colorGrayDarker};
    }

    .plan-card__cta {
      margin-top: 15px;
      padding: 0 50px;
    }

    .plan-card__features {
      list-style: none;
      text-align: left;
      margin: 20px 0 30px;
      padding-left: 0;
      display: none;

      li {
        line-height: 1.5em;

        .bp3-icon {
          opacity: 0.4;
          position: relative;
          top: -1px;
          margin-right: 4px;
        }
      }
    }

    @media screen and (max-width: 700px) {
      background: #fff;
      padding: 25px;
      margin-bottom: 20px;
      min-width: 300px;

      .plan-card__cta {
        padding: 0;
      }

      .plan-card__features {
        display: block;
      }
    }
  }

  .marketing-pricing__questions {
    padding: 40px 10px 0;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .pricing-why {
    background-color: ${props => props.theme.colorPurpleLighter};
  }
`

class Pricing extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.mixpanel.track('Public Page Viewed', {page: 'Pricing'});
  }

  render() {
    return (
      <StyledPricing>
        <Helmet>
          <title>Pricing</title>
          <meta property="og:title" content="Pricing | Wanderium" />
        </Helmet>
        <MarketingHeader />
        <div className="pricing-cover__wrapper">
          <div className="pricing-cover">
            <div className="pricing-cover__content">
              <h1>Upgrade your travel with Premium</h1>
              <div className="divider divider--m divider--thick"></div>
              <h2>Explore every end of the world with unlimited trips and more.</h2>
            </div>
          </div>
        </div>
        <div className="pricing-plans__wrapper">
          <div className="marketing-pricing__plans">
            <div className="marketing-pricing__plan-header marketing-pricing__plan-row">
              <div></div>
              <div></div>
              <div className="plan-row__plan-card">
                <h3>Free</h3>
                <p className="plan-card__descr">For occasional travelers.</p>
                <ul className="plan-card__features">
                  <li><Icon icon="tick" iconSize={12}/> 1 free trip per year</li>
                  <li><Icon icon="tick" iconSize={12}/> Get additional trips for $15 each</li>
                  <li><Icon icon="tick" iconSize={12}/> Prefilled checklists</li>
                  <li><Icon icon="tick" iconSize={12}/> Personalized guides</li>
                  <li><Icon icon="tick" iconSize={12}/> Customized packing lists</li>
                  <li><Icon icon="tick" iconSize={12}/> Trip monitoring</li>
                  <li><Icon icon="tick" iconSize={12}/> Recurring items</li>
                  <li><Icon icon="tick" iconSize={12}/> Recommendations</li>
                  <li><Icon icon="tick" iconSize={12}/> Reservation importing (coming soon)</li>
                  <li><Icon icon="tick" iconSize={12}/> Flight notifications (coming soon)</li>
                </ul>
                <div className="plan-card__cta">
                  <LinkButton fill intent="primary" rightIcon="caret-right" to="/signup">Sign up</LinkButton>
                </div>
              </div>
              <div className="plan-row__plan-card">
                <h3>Premium</h3>
                <p className="plan-card__descr">For frequent travelers.</p>
                <ul className="plan-card__features">
                  <li><Icon icon="tick" iconSize={12}/> Unlimited trips</li>
                  <li><Icon icon="tick" iconSize={12}/> Everything else in Free</li>
                  <li><Icon icon="tick" iconSize={12}/> VIP support</li>
                  <li><Icon icon="tick" iconSize={12}/> Access to Deal Club</li>
                </ul>
                <div className="divider divider--s"></div>
                <p className="plan-card__annual">$60/year</p>
              </div>
            </div>
            <div className="marketing-pricing__features">
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">Trips</div>
                <div></div>
                <div className="plan-row__feature-value">1 per year included<p>Get additional trips for $15/each</p></div>
                <div className="plan-row__feature-value"><strong>Unlimited</strong></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">Prefilled Checklists</div>
                <div></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">Personalized Guides</div>
                <div></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">Customized Packing Lists</div>
                <div></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">Trip Monitoring</div>
                <div></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">Recurring Items</div>
                <div></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">Recommendations</div>
                <div></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">
                  Import flight/lodging reservations
                  <p>Coming soon.</p>
                </div>
                <div></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">
                  Flight notifications
                  <p>Coming soon.</p>
                </div>
                <div></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">
                  VIP Support
                </div>
                <div></div>
                <div className="plan-row__feature-value"></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
              <div className="marketing-pricing__plan-row">
                <div className="plan-row__feature">
                  Deal Club
                  <p>Coming soon.</p>
                </div>
                <div></div>
                <div className="plan-row__feature-value"></div>
                <div className="plan-row__feature-value"><Icon icon="tick"/></div>
              </div>
            </div>
            <div className="marketing-pricing__questions">
              Have questions? <a href="mailto:yourfriends@wanderium.com" target="_blank" rel="noopener noreferrer">Let us know <Icon icon="arrow-right" iconSize={12} /></a>
            </div>
          </div>
        </div>
        <MarketingBlock className="pricing-why" noBorder header="What’s in it for us, and in turn you?" wideHeader>
          <h3 className="with-shape"><div className="shape parallelogram bg-beige-dark"></div>Confidence in our honesty</h3>
          <p>By upgrading to Premium, you help us stay honest because it means we&#39;re not reliant on recommending products that pay affiliate commissions to stay in business. We can continue to recommend what we think is best, not just what makes us money.</p>
          <p>Why does this matter? Just look to <a href="https://theoutline.com/post/4716/how-everything-on-the-internet-became-clickbait" target="_blank" rel="noopener noreferrer">clickbait filled news sites that depend on advertising</a> or <a href="https://www.fastcompany.com/3065928/sleepopolis-casper-bloggers-lawsuits-underside-of-the-mattress-wars" target="_blank" rel="noopener noreferrer">dishonest review sites</a> that <a href="https://www.xdesk.com/wirecutter-standing-desk-review-pay-to-play-model" target="_blank" rel="noopener noreferrer">depend on kickbacks</a>. When you rely on a single thing to survive, you tend to optimize for that at the expense of everything else, including your customers.</p>
          <h3 className="with-shape"><div className="shape square bg-teal-dark"></div>Confidence in our quality</h3>
          <p>To make Wanderium work, we have to maintain data on over 200 countries and 300 cities, keep up with the newest travel products, and stitch it all together into a beautiful app. By upgrading to Premium you’re helping ensure we have the resources to maintain quality across all these areas.</p>
          <h3 className="with-shape"><div className="shape triangle bc-orange-dark"></div>Confidence in our longevity</h3>
          <p>We want to help people travel smarter for a long time, and the #1 way to do that is to be a sustainable business. By upgrading to Premium and supporting us directly with your dollars, you help ensure a business you get value out of stays around.</p>
          <h3>We aim to be an independent business sustained by its customers. We’d love it if you joined us.</h3>
        </MarketingBlock>
        <MarketingSignUp />
        <MarketingFooter />
      </StyledPricing>
    );
  }
}

export default Pricing;