import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import LinkButton from '../shared/LinkButton';
import VisibilitySensor from 'react-visibility-sensor';

const StyledHomepageData = styled.div`
  padding: 70px 15px 90px;
  background: ${props => props.theme.colorPurpleLighter};


  .data__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .data__locs {
    display: flex;
    margin-bottom: 3rem;
    margin-top: -1rem;

    .data__loc:first-of-type {
      margin-right: 3rem;
    }
  }

  .data__loc {
    text-align: center;

    strong {
      font-size: 2.0736rem;
      font-weight: 600;
    }

    .data__loc-type {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }

  .data__attr-header {
    text-align: center;
    font-weight: 500;
    margin: 0 0 1rem 0;
  }

  .data__attr-cols {
    display: flex;

    .data__attr-col:first-of-type {
      margin-left: 0;
    }
  }

  .data__attr-col {
    margin-left: 3rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      padding-top: 8px;
    }

    .bp3-icon {
      opacity: 0.4;
      margin-right: 3px;
    }
  }

  .data__signup {
    text-align: center;
    padding: 60px 0 0;

    h2 {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 1.44rem;
    } 

    .bp3-button.bp3-intent-primary {
      background-color: ${props => props.theme.colorPurpleDarker};

      @media (pointer: fine) and (hover: hover) {
        &:hover {
          background-color: ${props => props.theme.colorPurpleDark};
        }
      }
    }
  }

  .count__plus {
    font-size: 1.2rem;
    font-weight: 600;
    position: relative;
    top: -4px;
    opacity: 0.5;
  }

  @media screen and (max-width: 770px) {
    .data__attr-cols {

      .data__attr-col:nth-child(2) {
        display: none;
      }
    }

    .data__attr-col {
      margin-left: 1rem;

      li {
        font-size: 13px;
      }
    }
  }
`

class HomepageData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counted: false
    }
  }
  handleVisChange = isVisible => {
    if (isVisible && !this.state.counted) {
      animateValue("count__countries", 100, 250, 600);
      animateValue("count__cities", 180, 430, 600);
      setTimeout(() => {
        this.setState({
          counted: true
        })
      }, 800);
    }
  }
  render() {
    return (
      <StyledHomepageData>
        <VisibilitySensor onChange={this.handleVisChange} delayedCall>
          <>
          <div className="marketing-section__header">
            <h2>Everything you need to know</h2>
            <div className="divider divider--m"></div>
            <p>Wanderium is backed by high quality, constantly updated data from hundreds of vetted sources.</p>
          </div>
          <div className="data__wrapper">
            <div className="data__locs">
              <div className="data__loc">
                  <strong id="count__countries">250</strong>
                <div className="data__loc-type">
                  Countries
                </div>
              </div>
              <div className="data__loc">
                <strong id="count__cities">430</strong>{this.state.counted && <span className="count__plus">+</span>}
                <div className="data__loc-type">
                  Cities
                </div>
              </div>
            </div>
            <div className="data__attributes">
              <h3 className="data__attr-header">
                Some of our available information
              </h3>
              <div className="data__attr-cols">
                <div className="data__attr-col">
                  <ul>
                    <li><Icon icon="tick" iconSize={12}/> Visa requirements</li>
                    <li><Icon icon="tick" iconSize={12}/> Passport validity requirements</li>
                    <li><Icon icon="tick" iconSize={12}/> Passport blank page requirements</li>
                    <li><Icon icon="tick" iconSize={12}/> Onward proof requirements</li>
                    <li><Icon icon="tick" iconSize={12}/> Local weather</li>
                    <li><Icon icon="tick" iconSize={12}/> Air quality</li>
                    <li><Icon icon="tick" iconSize={12}/> Elevation</li>
                    <li><Icon icon="tick" iconSize={12}/> Timezone conversion</li>
                    <li><Icon icon="tick" iconSize={12}/> Wall plugs and voltage</li>
                  </ul>
                </div>
                <div className="data__attr-col">
                  <ul>
                    <li><Icon icon="tick" iconSize={12}/> Internet speeds</li>
                    <li><Icon icon="tick" iconSize={12}/> Vaccine requirements</li>
                    <li><Icon icon="tick" iconSize={12}/> Malaria risk</li>
                    <li><Icon icon="tick" iconSize={12}/> Zika risk</li>
                    <li><Icon icon="tick" iconSize={12}/> Yellow fever risk</li>
                    <li><Icon icon="tick" iconSize={12}/> Censorship</li>
                    <li><Icon icon="tick" iconSize={12}/> Languages</li>
                    <li><Icon icon="tick" iconSize={12}/> Driving permit</li>
                    <li><Icon icon="tick" iconSize={12}/> Road safety</li>
                  </ul>
                </div>
                <div className="data__attr-col">
                  <ul>
                    <li><Icon icon="tick" iconSize={12}/> Currency conversion</li>
                    <li><Icon icon="tick" iconSize={12}/> Tipping norms</li>
                    <li><Icon icon="tick" iconSize={12}/> Tax free shopping</li>
                    <li><Icon icon="tick" iconSize={12}/> Daily cost</li>
                    <li><Icon icon="tick" iconSize={12}/> Dress code</li>
                    <li><Icon icon="tick" iconSize={12}/> Safety advisory</li>
                    <li><Icon icon="tick" iconSize={12}/> Foreigner friendly</li>
                    <li><Icon icon="tick" iconSize={12}/> Female friendly</li>
                    <li><Icon icon="tick" iconSize={12}/> LGBT friendly</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          </>
        </VisibilitySensor>
        <div className="data__signup">
          <h2>Travel smarter with Wanderium</h2>
          <LinkButton large intent="primary" rightIcon="caret-right" to='/signup'>Sign up for free</LinkButton>
        </div>
      </StyledHomepageData>
    )
  }
}

export default HomepageData;

function animateValue(id, start, end, duration) {
  // https://stackoverflow.com/questions/16994662/count-animation-from-number-a-to-b
  var obj = document.getElementById(id);
  var range = end - start;
  var minTimer = 50;
  var stepTime = Math.abs(Math.floor(duration / range));
  stepTime = Math.max(stepTime, minTimer);
  var startTime = new Date().getTime();
  var endTime = startTime + duration;
  var timer;
  function run() {
      var now = new Date().getTime();
      var remaining = Math.max((endTime - now) / duration, 0);
      var value = Math.round(end - (remaining * range));
      obj.innerHTML = value;
      if (value === end) {
          clearInterval(timer);
      }
  }
  timer = setInterval(run, stepTime);
  run();
}