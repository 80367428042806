import React, { Component } from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

const colorArray=['colorOrange', 'colorPink', 'colorYellow', 'colorGreen', 'colorTeal', 'colorBlue', 'colorPurple', 'colorRed'];

const StyledRecTopicCard = styled(Link)`
  cursor: pointer;
  margin-bottom: 1rem;
  transition: transform 120ms ease-in-out;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;

  .rec-topic__pic {
    height: 140px;
    width: 100%;
    background-image: url(${props => props.pic});
    background-color: ${props => props.theme.colorGray};
    background-position: 50% 50%;
    background-size: cover;
    position: relative;

    &:before {
      background-color: ${props => props.theme[props.colorBefore] ? darken(0.03, props.theme[props.colorBefore]) : null};
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      mix-blend-mode: darken;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      background-color: ${props => props.theme[props.colorAfter] ? darken(0.05, props.theme[props.colorAfter]) : null};
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      mix-blend-mode: lighten;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .rec-topic__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    background-color: #fff;
    padding: 20px;
    width: 100%;

    h3 {
      margin: -3px 0 0;
      font-weight: 600;
      line-height: 1em;
      font-family: ${props => props.theme.fontHeading};
      font-size: 1.728rem;
      text-transform: uppercase;
    }

    h4 {
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 0;
      color: ${props => props.theme.colorGrayDark};
      margin-top: 3px;
    }

    p {
      margin: 0.5rem 0 0;
      color: #333;
    }
  }

  .rec-topic__card-noun {
    .long {
      display: inline;
    }

    .short {
      display: none;
    }

    @media screen and (max-width: 530px) {
      .long {
        display: none;
      }

      .short {
        display: inline;
      }
    }
  }

  @media (pointer: fine) and (hover: hover) {
    &:hover {
      transform: scale(1.05);
      text-decoration: none;

      h3 {
        color: ${props => props.theme.colorPrimary};
      }
    }
  }
`

class RecTopicCard extends Component {
  constructor(props) {
    super(props);
    if (props.rec.pic) {
      this.cardPic = 'https://wanderium.imgix.net' + this.props.rec.pic.split('wanderium')[1] + '?w=350&dpr=2&q=65';
    }
    this.colorNum1 = Math.floor(Math.random() * colorArray.length);
    this.colorNum2 = Math.floor(Math.random() * colorArray.length);
    if (this.colorNum2 === this.colorNum1) {
      this.colorNum2++;
    }
    this.colorBefore = colorArray[this.colorNum1] + 'Lighter';
    this.colorAfter = colorArray[this.colorNum2] + 'Darker';
  }

  render() { 
    const { topicId, sentence, recCount, pic, summary } = this.props.rec;
    return (
      <StyledRecTopicCard className="rec-topic__card" pic={this.cardPic} colorBefore={this.colorBefore} colorAfter={this.colorAfter} to={`/recs/${topicId}`}>
        {pic && <div className="rec-topic__pic"></div>}
        <div className="rec-topic__content">
          <h3>{sentence}</h3>
          <h4>{recCount} <span className="rec-topic__card-noun"><span className="long">{recCount === 1 ? 'recommendation' : 'recommendations'}</span><span className="short">{recCount === 1 ? 'pick' : 'picks'}</span></span></h4>
          {summary && <p>{summary}</p>}
        </div>
      </StyledRecTopicCard>
    )
  }
}

export default RecTopicCard;