import React, { Component } from 'react';
import styled from 'styled-components';

const colorArray=['colorOrange', 'colorPink', 'colorYellow', 'colorGreen', 'colorTeal', 'colorBlue', 'colorPurple', 'colorRed', 'colorBeige'];

const StyledAngledBorder = styled.div`
  position: relative;

  &:after {
    content: '';
    background-color: ${props => props.theme[props.color]};
    position: absolute;
    top: -8px;
    left: -8px;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    z-index: -1;
    transform: rotate(${props => props.angle}deg);
  }
`

class AngledBorder extends Component {
  constructor(props) {
    super(props);
    // Angle formula: Part 1 = angle, part 2 = direction (positive/negative)
    this.angle = (((Math.random() * .85) + 0.4).toFixed(2)) * (Math.random() > 0.5 ? 1 : -1);
    this.color = colorArray[Math.floor(Math.random() * colorArray.length)];
  }
  
  render() {
    return (
      <StyledAngledBorder
        angle={this.angle}
        color={this.color}
      >
        {this.props.children}
      </StyledAngledBorder>
    )
  }
}

export default AngledBorder;