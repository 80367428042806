import React, { Component } from 'react';
import styled from 'styled-components';
import { Icon } from '@blueprintjs/core';
import throttle from 'lodash/throttle';

const bp = '680px';
const StyledHomepageWhy = styled.div`
  padding: 80px 15px;
  background: ${props => props.theme.colorYellowLight};

  .marketing-section__header.why {
    margin-bottom: 80px;
    margin-top: 30px;

    h1 {
      font-weight: 500;
    }
  }

  .why-blocks {
    .why-block:last-child {
      margin-bottom: 0;
    }
  }

  .why-block {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .why-block__txt {
      margin-right: 40px;
    }

    &.--anim-left {
      .why-block__txt {
        margin-left: 40px;
        margin-right: 0;
        order: 2;
      }

      .why-block__anim {
        order: 1;
      }
    }

    &.why-block__quick {
      margin-top: -30px;
    }

    &.why-block__tailored {
      margin: -60px 0 -30px;
    }

    @media screen and (max-width: ${bp}) {
      flex-direction: column;
      margin-bottom: 45px;

      &.why-block__quick {
        margin-top: 0;
      }

      &.why-block__tailored {
        margin: -10px 0 20px;
      }

      .why-block__txt {
        margin-right: 0;
      }

      &.--anim-left {
        .why-block__txt {
          margin-left: 0;
          order: 0;
        }

        .why-block__anim {
          order: 1;
        }
      }
    }
  }

  .why-block__txt {
    max-width: 350px;

    h3 {
      margin-bottom: 4px;
    }

    p {
      color: ${props => props.theme.colorGrayDarker};
      font-size: 1.1rem;
      margin-top: -3px;
    }

    @media screen and (max-width: ${bp}) {
      margin-bottom: 10px;
      width: 100%;
      max-width: 400px;
    }
  }

  .why-block__anim {
    max-width: 400px;
    width: 100%;
    height: 300px;
    position: relative;

    &.disabled {
      > * {
        animation-play-state: paused !important;
      }
    }
  }

  // Animations
  .why-anim__quick {

    .shape, .btn-fake {
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .square {
      animation: anim-quick__square 3s ease-in-out 0s infinite both;
      background-color: ${props => props.theme.colorOrangeDark};
    }

    .circle {
      animation: anim-quick__circle 3s ease-in-out 0s infinite both;
      background-color: ${props => props.theme.colorRedDark};
      transform-origin: bottom right;
    }

    .triangle {
      animation: anim-quick__triangle 3s ease-in-out 0s infinite both;
      border-color: ${props => props.theme.colorGreenDark};
    }

    .parallelogram {
      animation: anim-quick__parallel 3s ease-in-out 0s infinite both;
      background-color: ${props => props.theme.colorBlueDark};
    }

    .btn-fake {
      width: 5rem;
      height: 2rem;
      background: ${props => props.theme.colorGrayDarkest};
      animation: anim-quick__btn 3s ease-in-out 0s infinite both;
      margin: -1rem 0 0 -2.5rem; // For centering
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }

  @keyframes anim-quick__square {
    0% {
      transform: rotate(0deg) translate3d(-50%, -50%, 0);
      opacity: 0;
      animation-timing-function: ease-in-out;
    }

    5% {
      transform: rotate(0deg) translate3d(-50%, -50%, 0);
      opacity: 0;
      animation-timing-function: ease-in-out;
    }

    16% {
      transform: rotate(-15deg) translate3d(-115px, -125px, 0);
      opacity: 1;
      animation-timing-function: ease-in-out;
    }

    80% {
      transform: rotate(-15deg) translate3d(-115px, -125px, 0);
      opacity: 1;
    }

    85% {
      transform: rotate(-15deg) translate3d(-115px, -125px, 0);
      opacity: 0;
    }

    100% {
      transform: rotate(-15deg) translate3d(-115px, -125px, 0);
      opacity: 0;
    }
  }

  @keyframes anim-quick__circle {
    0% {
      transform: rotate(0deg) translate3d(-50%, -50%, 0);
      opacity: 0;
      animation-timing-function: ease-in-out;
    }

    5% {
      transform: rotate(0deg) translate3d(-50%, -50%, 0);
      opacity: 0;
      animation-timing-function: ease-in-out;
    }

    18% {
      transform: rotate(20deg) translate3d(92px, 25px, 0);
      opacity: 1;
      animation-timing-function: ease-in-out;
    }

    80% {
      transform: rotate(20deg) translate3d(92px, 25px, 0);
      opacity: 1;
    }

    85% {
      transform: rotate(20deg) translate3d(92px, 25px, 0);
      opacity: 0;
    }

    100% {
      transform: rotate(20deg) translate3d(92px, 25px, 0);
      opacity: 0;
    }
  }

  @keyframes anim-quick__triangle {
    0% {
      transform: rotate(0deg) translate3d(-50%, -50%, 0);
      opacity: 0;
      animation-timing-function: ease-in-out;
    }

    5% {
      transform: rotate(0deg) translate3d(-50%, -50%, 0);
      opacity: 0;
      animation-timing-function: ease-in-out;
    }

    21% {
      transform: rotate(32deg) translate3d(4px, -135px, 0);
      opacity: 1;
      animation-timing-function: ease-in-out;
    }

    80% {
      transform: rotate(32deg) translate3d(4px, -135px, 0);
      opacity: 1;
    }

    85% {
      transform: rotate(32deg) translate3d(4px, -135px, 0);
      opacity: 0;
    }

    100% {
      transform: rotate(32deg) translate3d(4px, -135px, 0);
      opacity: 0;
    }
  }

  @keyframes anim-quick__parallel {
    0% {
      transform: rotate(0deg) skew(27deg) translate3d(-50%, -50%, 0);
      opacity: 0;
      animation-timing-function: ease-in-out;
    }

    5% {
      transform: rotate(0deg) skew(27deg) translate3d(-50%, -50%, 0);
      opacity: 0;
      animation-timing-function: ease-in-out;
    }

    14% {
      transform: rotate(-18deg) skew(27deg) translate3d(-137px, 4px, 0);
      opacity: 1;
      animation-timing-function: ease-in-out;
    }

    80% {
      transform: rotate(-18deg) skew(27deg) translate3d(-137px, 4px, 0);
      opacity: 1;
    }

    85% {
      transform: rotate(-18deg) skew(27deg) translate3d(-137px, 4px, 0);
      opacity: 0;
    }

    100% {
      transform: rotate(-18deg) skew(27deg) translate3d(-137px, 4px, 0);
      opacity: 0;
    }
  }

  @keyframes anim-quick__btn {
    0% {
      background-color: ${props => props.theme.colorGrayDarkest};
    }

    4% {
      background-color: ${props => props.theme.colorGrayDarkest};
    }

    5% {
      background-color: ${props => props.theme.colorTealDark};
      transform: scale(1.1);
    }

    20% {
      background-color: ${props => props.theme.colorGrayDarkest};
      transform: scale(1);
    }
    
    100% {
      background-color: ${props => props.theme.colorGrayDarkest};
    }
  }

  .why-anim__tailored {

    .shape {
      position: absolute;
    }

    .square {
      left: 47%;
      top: 45%;
      animation: anim-tailored__square 5s ease-in-out 0s infinite both;
      transform: rotate(15deg) translate3d(45px, 30px, 0);
      background-color: ${props => props.theme.colorPurpleDark};
    }

    .circle {
      left: 42%;
      top: 27%;
      animation: anim-tailored__circle 5s ease-in-out 0s infinite both;
      transform: rotate(0deg) translate3d(50px, -40px, 0);
      background-color: ${props => props.theme.colorTealDark};
    }

    .triangle {
      left: 35%;
      top: 42%;
      animation: anim-tailored__triangle 5s ease-in-out 0s infinite both;
      transform: rotate(55deg) translate3d(-7px, 65px, 0);
      border-color: ${props => props.theme.colorBeigeDark};
    }

    .parallelogram {
      left: 32%;
      top: 27%;
      animation: anim-tailored__parallel 5s ease-in-out 0s infinite both;
      transform: rotate(12deg) skew(27deg) translate3d(-45px, -33px, 0);
      background-color: ${props => props.theme.colorPinkDark};
    }
  }

  @keyframes anim-tailored__square {
    0% {
      transform: rotate(15deg) translate3d(45px, 30px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    20% {
      transform: rotate(15deg) translate3d(45px, 30px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    35% {
      transform: rotate(0deg) translate3d(-15px, -10px, 0);
      animation-timing-function: ease-in-out;
      opacity: 0.6;
    }

    85% {
      transform: rotate(0) translate3d(-15px, -10px, 0);
      animation-timing-function: ease-in-out;
      opacity: 0.6;
    }

    100% {
      transform: rotate(15deg) translate3d(45px, 30px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  }

  @keyframes anim-tailored__circle {
    0% {
      transform: rotate(0deg) translate3d(50px, -40px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    20% {
      transform: rotate(0deg) translate3d(50px, -40px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    35% {
      transform: rotate(-15deg) translate3d(0, 0, 0);
      animation-timing-function: ease-in-out;
      opacity: 0.6;
    }

    85% {
      transform: rotate(-15deg) translate3d(0, 0, 0);
      animation-timing-function: ease-in-out;
      opacity: 0.6;
    }

    100% {
      transform: rotate(0deg) translate3d(50px, -40px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  }

  @keyframes anim-tailored__triangle {
    0% {
      transform: rotate(55deg) translate3d(-7px, 65px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    20% {
      transform: rotate(55deg) translate3d(-7px, 65px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    35% {
      transform: rotate(40deg) translate3d(-5px, -15px, 0);
      animation-timing-function: ease-in-out;
      opacity: 0.6;
    }

    85% {
      transform: rotate(40deg) translate3d(-5px,-15px, 0);
      animation-timing-function: ease-in-out;
      opacity: 0.6;
    }

    100% {
      transform: rotate(55deg) translate3d(-7px, 65px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  }

  @keyframes anim-tailored__parallel {
    0% {
      transform: rotate(12deg) skew(27deg) translate3d(-45px, -33px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    20% {
      transform: rotate(12deg) skew(27deg) translate3d(-45px, -33px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }

    35% {
      transform: rotate(0deg) skew(27deg) translate3d(5px, 5px, 0);
      animation-timing-function: ease-in-out;
      opacity: 0.6;
    }

    85% {
      transform: rotate(0deg) skew(27deg) translate3d(5px, 5px, 0);
      animation-timing-function: ease-in-out;
      opacity: 0.6;
    }

    100% {
      transform: rotate(12deg) skew(27deg) translate3d(-45px, -33px, 0);
      animation-timing-function: ease-in-out;
      opacity: 1;
    }
  }

  .why-anim__simple {
    .shape, .btn-fake {
      position: absolute;
      bottom: 10px;
      left: 50%;
      margin-left: -0.5em;
    }

    .square {
      animation: anim-simple__square 4s ease-in-out 0s infinite both;
      background-color: ${props => props.theme.colorYellowDark};
    }

    .circle {
      animation: anim-simple__circle 4s ease-in-out 0s infinite both;
      background-color: ${props => props.theme.colorPinkDark};
      transform-origin: bottom right;
    }

    .triangle {
      animation: anim-simple__triangle 4s ease-in-out 0s infinite both;
      border-color: ${props => props.theme.colorBlueDark};
    }

    .parallelogram {
      animation: anim-simple__parallel 4s ease-in-out 0s infinite both;
      background-color: ${props => props.theme.colorOrangeDark};
    }
  }

  @keyframes anim-simple__square {
    0% {
      transform: translate3d(0,0,0);
      opacity: 0;
    }

    5% {
      opacity: 1;
    }

    35% {
      opacity: 1;
    }

    38% {
      transform: translate3d(0, -230px, 0);
      opacity: 1;
    }

    42% {
      transform: translate3d(0, -230px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, -230px, 0);
      opacity: 0;
    }
  }


  @keyframes anim-simple__circle {
    0% {
      transform: translate3d(0,0,0);
      opacity: 0;
    }

    11% {
      transform: translate3d(0,0,0);
      opacity: 0;
    }

    15% {
      opacity: 1;
    }

    24% {
      transform: translate3d(0, -80px, 0);
    }

    46% {
      transform: translate3d(-155px, -80px, 0);
      opacity: 1;
    }

    50% {
      transform: translate3d(-155px, -80px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(-155px, -80px, 0);
      opacity: 0;
    }
  }

  @keyframes anim-simple__triangle {
    0% {
      transform: translate3d(0,0,0);
      opacity: 0;
    }

    22% {
      transform: translate3d(0,0,0);
      opacity: 0;
    }

    26% {
      opacity: 1;
    }

    45% {
      transform: translate3d(0, -140px, 0);
    }

    71% {
      transform: translate3d(155px, -140px, 0);
      opacity: 1;
    }

    75% {
      transform: translate3d(155px, -140px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(155px, -140px, 0);
      opacity: 0;
    }
  }

  @keyframes anim-simple__parallel {
    0% {
      transform: skew(27deg) translate3d(0,0,0);
      opacity: 0;
    }

    33% {
      transform: skew(27deg) translate3d(0,0,0);
      opacity: 0;
    }

    37% {
      opacity: 1;
    }

    66% {
      transform: skew(27deg) translate3d(105px, -200px, 0);
    }

    92% {
      transform: skew(27deg) translate3d(-50px, -200px, 0);
      opacity: 1;
    }

    96% {
      transform: skew(27deg) translate3d(-50px, -200px, 0);
      opacity: 0;
    }

    100% {
      transform: skew(27deg) translate3d(-50px, -200px, 0);
      opacity: 0;
    }
  }
`

class HomepageWhy extends Component {
  constructor(props) {
    super(props);
    this.throttledScroll = throttle(this.listenScrollEvent, 300);
    this.SectionQuick = React.createRef();
    this.SectionTailored = React.createRef();
    this.SectionSimple = React.createRef();
    this.state = {
      quickAnim: true,
      tailoredAnim: false,
      simpleAnim: false
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttledScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll);
  }

  listenScrollEvent = () => {
    if (isScrolledIntoView(this.SectionQuick.current) !== this.state.quickAnim) {
      this.setState(state => ({
        quickAnim: !state.quickAnim
      }))
    }

    if (isScrolledIntoView(this.SectionTailored.current) !== this.state.tailoredAnim) {
      this.setState(state => ({
        tailoredAnim: !state.tailoredAnim
      }))
    }

    if (isScrolledIntoView(this.SectionSimple.current) !== this.state.simpleAnim) {
      this.setState(state => ({
        simpleAnim: !state.simpleAnim
      }))
    }
  }

  render() {
    return (
      <StyledHomepageWhy>
        <div className="marketing-section__header why">
          <h1>Take the guesswork out of travel</h1>
          <div className="divider divider--m"></div>
          <p>We use personalization, data, and good ol’ human expertise to help you travel smarter.</p>
        </div>
        <div className="why-blocks">
          <div className="why-block why-block__quick" ref={this.SectionQuick}>
            <div className="why-block__txt">
              <h3>Quick and easy</h3>
              <div className="divider divider--s divider--left"></div>
              <p>Tell us about your trip and in seconds receive a personalized checklist, packing list, tips, and more. No brain power needed.</p>
            </div>
            <div className={`why-block__anim why-anim__quick${this.state.quickAnim ? '' : ' disabled'}`}>
              <div className="shape shape--l square"></div>
              <div className="shape shape--l circle"></div>
              <div className="shape shape--l triangle"></div>
              <div className="shape shape--l parallelogram"></div>
              <div className="btn-fake"><Icon icon="arrow-right"/></div>
            </div>
          </div>
          <div className="why-block why-block__tailored --anim-left" ref={this.SectionTailored}>
            <div className="why-block__txt">
              <h3>Tailored to you</h3>
              <div className="divider divider--s divider--left"></div>
              <p>One size does not fit all. We personalize everything to your unique circumstances and needs.</p>
            </div>
            <div className={`why-block__anim why-anim__tailored${this.state.tailoredAnim ? '' : ' disabled'}`}>
              <div className="shape shape--xl square"></div>
              <div className="shape shape--xl circle"></div>
              <div className="shape shape--xl triangle"></div>
              <div className="shape shape--xl parallelogram"></div>
            </div>
          </div>
          <div className="why-block why-block__simple" ref={this.SectionSimple}>
            <div className="why-block__txt">
              <h3>Guided simplicity</h3>
              <div className="divider divider--s divider--left"></div>
              <p>Thoughtful details that take the guesswork out of what’s important, when things need to be done, or when circumstances change.</p>
            </div>
            <div className={`why-block__anim why-anim__simple${this.state.simpleAnim ? '' : ' disabled'}`}>
              <div className="shape shape--l square"></div>
              <div className="shape shape--l circle"></div>
              <div className="shape shape--l triangle"></div>
              <div className="shape shape--l parallelogram"></div>
            </div>
          </div>
        </div>
      </StyledHomepageWhy>
    );
  }
}

export default HomepageWhy;

function isScrolledIntoView(el) {
  // Set to detect if element is 5% within window
  if (el) {
    let
      rect = el.getBoundingClientRect(),
      windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    return !(
      Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100)) < 0.05 ||
      Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < 0.05
    )
  } else {
    return null;
  }
}