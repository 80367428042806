import React, { Component } from 'react';
import styled from 'styled-components';

const StyledInactiveHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 100%;
  background-color: ${(props) => props.theme.colorRedDark};
  color: #fff;
  font-weight: 600;

  a {
    padding: 0 0 0 5px;
    color: #fff;
    text-decoration: underline;

    &:hover {
      color: #fff;
    }
  }
`;

class MarketingInactiveBanner extends Component {
  render() {
    return (
      <StyledInactiveHeader>
        Wanderium is no longer under active development. If you're interested in taking over, please <a href="mailto:jono@wanderium.com">reach out</a>.
      </StyledInactiveHeader>
    );
  }
}

export default MarketingInactiveBanner;