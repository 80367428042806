import React, { Component } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

const StyledMarketingVid = styled.div`
  .vid-browser {
    box-shadow: 0 -10px 25px ${props => props.theme.colorGrayLight};
    position: relative;
    background-color: ${props => props.theme.colorTealLighter};
    width: 600px;
    height: 400px;

    video {
      max-width: 600px;
      width: 100%;
    }

    &:before {
      content: '';
      position: absolute;
      top: -30px;
      left: 0;
      right: 0;
      height: 30px;
      background-color: ${props => props.theme.colorGrayLight};
      border-radius: 5px 5px 0 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: -23px;
      left: 50%;
      transform: translateX(-50%);
      width: 200px;
      height: 16px;
      background-color: #fff;
      border-radius: 3px;
    }
  }
  
  .vid-browser__btns {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 9px;
    top: -21px;
    background-color: ${props => props.theme.colorRedDark};
    border-radius: 50%;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 15px;
      top: 0;
      background-color: ${props => props.theme.colorYellow};
      border-radius: 50%;
    }

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      left: 30px;
      top: 0;
      background-color: ${props => props.theme.colorGreen};
      border-radius: 50%;
    }
  }

  @media screen and (max-width: 580px) {
    .vid-browser {
      margin-top: 45px;
      width: 100%;
      height: auto;
    }
  }
`

class MarketingVid extends Component {
  constructor(props) {
    super(props);
    this[`Video#${props.id}`] = React.createRef();
    this.playPromise = null;
    this.isPlaying = false;
  }

  handleVisChange = isVisible => {
    //console.log('video ID: ', this.props.id);
    //console.log('this.Video ref: ', this[`Video#${this.props.id}`]);

    if (isVisible && this[`Video#${this.props.id}`].current.paused) {
      //console.log('play');
      //this.playPromise = this[`Video#${this.props.id}`].current.play();
      //this.played = true;

      this.playPromise = this[`Video#${this.props.id}`].current.play()
        .then(_ => {
          // Let it play
        }).catch(_ => {
          // Do nothing
        })
    } else if (!isVisible && !this[`Video#${this.props.id}`].current.paused) {
      // console.log('pause');
      // this.playPromise.then(_ => {
      //   this[`Video#${this.props.id}`].current.pause();
      // }).catch(_ => {
      //   //console.log('playPromise error: ', err);
      // })

      if (this.playPromise !== undefined) {
        this[`Video#${this.props.id}`].current.pause();
      }

    }
  }

  render() {
    const { src, alt, id } = this.props;
    return (
      <VisibilitySensor onChange={this.handleVisChange} partialVisibility="top">
        <StyledMarketingVid>
          <div className="vid-browser">
            <video ref={this[`Video#${id}`]} loop alt={alt} muted playsInline>
              <source src={src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="vid-browser__btns"></div>
          </div>
        </StyledMarketingVid>
      </VisibilitySensor>
    );
  }
}

export default MarketingVid;